// import Topbar from "./Topbar";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Tabs, Tab } from "react-bootstrap";
// import "aos/dist/aos.css";
// import AOS from "aos";
// import jsonData from './local_api/accessibility.json';

// import "aos/dist/aos.css";

// import "./BasicComputingCourse.css";
// import ShajibKhadem from "./assets/Shajib-khadem.jpg";
// import RifatHalim from "./assets/Rifat-Halim.JPG";
// import FaridUddin from "./assets/Farid-Uddin.jpeg";

// function SoftwareTestingCourse() {
//   AOS.init();

//   // data fetching function for localapi
//   function fetchDataById(id) {
//     const result = jsonData.find(item => item.id === id);
//     return result ? result : null;
//   }
//   const courseDetails = fetchDataById(2);

//   return (
//     <>
//       <Navbar />

//       {/* Slider start */}

//       <section className="bgimage">
//         <div className="container">
//           <div className="row par">
//             <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
//               <h1>{courseDetails.course_name}</h1>
//               <br />
//               <p>
//                 {courseDetails.course_description}
//               </p>
//               <h3 className="mb-3">Upcoming Batch Details</h3>
//               <p>
//                 Main Course: {courseDetails.start_date}
//                 <br />
//                 Trial Class: {courseDetails.trial_date}
//               </p>
//               <p>
//                 <a href="#price" className="btn btn-primary btn-large">
//                   Course Fee »
//                 </a>
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Slider end */}

//       {/* course info start */}
//       <section>
//         <div id="parallax-world-of-ugg">
//           <div className="container">
//             <div className="section-title text-center">
//               <h2>Course Overview</h2>
//             </div>
//             <div className="block">
//               <p>
//                 Manual Testing is a type of Software Testing where Testers
//                 manually test the software application. Manual Testing is the
//                 most primitive of all testing types and helps find bugs in the
//                 software system. JIRA is an issue management tool and it helps
//                 to facilitate the testing process and to manage various types of
//                 issues in the software development process. Manual Testing does
//                 not require knowledge of any testing tool.<br></br>
//                 <br></br>
//                 Testing with SQL is also known as Database Testing. The data
//                 entered in the front end will be stored in the back-end
//                 database. The database may be Oracle, PostgreSQL, etc. The data
//                 will be organized in the tables as a record, and it is used to
//                 support the content of the page. Database or backend testing is
//                 important because if it is not done it has some serious
//                 complications like deadlock, data corruption, data loss, etc.
//                 <br></br>
//                 <br></br>
//                 Application Programming Interface (API) enables communication
//                 and data exchange between two separate software systems an
//                 example is a front end and the backend database. API Testing is
//                 a software testing type that validates APIs. The purpose of API
//                 Testing is to check the functionality, reliability, performance,
//                 and security of the programming interfaces.<br></br>
//                 <br></br>
//                 Automation Testing means using an automation tool to test the
//                 software application. On the contrary, Manual Testing is
//                 performed by a human sitting in front of a computer carefully
//                 executing the test steps. Selenium is an open-source automation
//                 tool that is currently most used all over the world and it
//                 supports various programming languages such as Java, Python, C#,
//                 Ruby, etc.
//               </p>
//               {/* <p className="line-break margin-top-10" />
//               <p className="margin-top-10">
//                 This course will provide complete guidance on how to provide
//                 technical assistance and support related to IT systems, hardware
//                 & software solutions as well as to manage customer expectations
//                 with professionalism in a timely manner.
//               </p> */}
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* course info end */}

//       {/* Tab start */}

//       <div className="container">
//         <div className="row" data-aos="fade-up">
//           <div className="col-12">
//             <section className="payment">
//               <Tabs
//                 defaultActiveKey="home"
//                 id="uncontrolled-tab-example"
//                 className="tab-menu"
//               >
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="home"
//                   title="Course Information"
//                 >
//                   <ul>
//                     <li>
//                       Weekend Classes: Saturday & Sunday, 10 am – 2 pm | New
//                       York Time
//                     </li>
//                     {/* <li>
//                       Weekdays Classes: Monday, Wednesday & Friday, 6.30 pm –
//                       9.30 pm | New York Time{" "}
//                     </li> */}
//                     <li>
//                       Student Support Session: Thursday, 9 pm – 10 pm | New York
//                       Time
//                     </li>
//                     <li>Start Date: {courseDetails.start_date} (Weekend Batch)</li>
//                     {/* <li>Start Date: January 24, 2022 (Weekdays Batch)</li> */}
//                     <li>
//                       Duration: 12 Weeks.
//                       <ul>
//                         <li>Regular Class: 96 Hours</li>
//                         <li>Student Support Session: 12 Hours</li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </Tab>
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="profile"
//                   title="Who Can Join"
//                 >
//                   <ul>
//                     <li>Bachelor’s degree from any discipline</li>
//                     <li>Proficiency in English will be a huge plus</li>
//                   </ul>
//                 </Tab>
//                 {/* <Tab
//                   className="tab-item tabcourse"
//                   eventKey="prerequisites"
//                   title="Prerequisites"
//                 >
//                   <ul>
//                     <li>
//                       A motivated person with a Bachelor degree in any
//                       discipline who is seeking an IT professional job.
//                     </li>
//                   </ul>
//                 </Tab> */}
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="contact"
//                   title="Learning Outcome"
//                 >
//                   <ul>
//                     <li>
//                       Students will be capable of performing manual & automation
//                       testing in software development companies.
//                     </li>
//                   </ul>
//                 </Tab>
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="check"
//                   title="Instruction Method"
//                 >
//                   <ul>
//                     <li>Instructor led (Online only)</li>
//                   </ul>
//                 </Tab>
//               </Tabs>
//             </section>
//           </div>
//         </div>
//       </div>

//       {/* Tab end */}

//       {/* Accordion start */}

//       <section>
//         <div class="accordion container" id="accordionExample">
//           <div className="section-title text-center">
//             <h2>Course Outline</h2>
//           </div>
//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingOne">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseOne"
//                 aria-expanded="true"
//                 aria-controls="collapseOne"
//               >
//                 MODULE 1: Basic Computing
//               </button>
//             </h2>
//             <div
//               id="collapseOne"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingOne"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <p className="fw-bold">Computer Fundamentals:</p>
//                   <li>Computer Architecture including hardware and software</li>
//                   <li>Operating Systems Basics (Windows, macOS)</li>
//                   <li>Managing files in different Operating Systems</li>
//                   <li>Remote Desktop, Internet, VPN, Proxy Server</li>
//                   <li>Office Communication Tool such as Slack</li>
//                 </ul>

//                 <ul>
//                   <p className="mt-5 fw-bold">
//                     Google Suite/Microsoft (MS) Office:
//                   </p>
//                   <li>Google Docs/MS Word, Google Sheets/MS Excel</li>
//                   <li>Google Slides /MS PowerPoint, Gmail/MS Outlook</li>
//                 </ul>

//                 <ul>
//                   <p className="mt-5 fw-bold">
//                     Networking and Customer Service:
//                   </p>
//                   <li>Networking Concepts, IP Addressing, DHCP</li>
//                   <li>Networking Commands such as ipconfig, ping, nslookup</li>
//                   <li>Linux Operating System Basics and Installation Guide</li>
//                   <li>Linux Commands such as pwd, mkdir, touch</li>
//                   <li>
//                     Customer Service Principles, Problem Solving, Effective
//                     Communication
//                   </li>
//                   <li>CRM software such as HubSpot, JIRA</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingTwo">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseTwo"
//                 aria-expanded="false"
//                 aria-controls="collapseTwo"
//               >
//                 MODULE 2: Manual Testing
//               </button>
//             </h2>
//             <div
//               id="collapseTwo"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingTwo"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <p className="fw-bold">Software Testing and SDLC:</p>
//                   <li>
//                     What is Software? Discussion on the importance of Software
//                     in our daily life.
//                   </li>
//                   <li>
//                     What is Software Testing? What are the principles of
//                     Software Testing?
//                   </li>
//                   <li>Software Testing Trends and Future Perspective</li>
//                   <li>
//                     Introduction to SDLC(Software Development Lifecycle).
//                     Discussion on the different phases of SDLC
//                     <ul>
//                       <li>Planning & Requirement Analysis</li>
//                       <li>Defining Requirements</li>
//                       <li>Designing the product</li>
//                       <li>Building or Developing the product</li>
//                       <li>Testing</li>
//                       <li>Deployment & Maintenance</li>
//                     </ul>
//                   </li>
//                   <li>Why testing is a crucial part of SDLC?</li>
//                 </ul>

//                 <ul>
//                   <p className="fw-bold mt-5">Various kind of Testing:</p>
//                   <li>
//                     Discussion of various kinds of Software Testing.
//                     <ul>
//                       <li>Dynamic Testing</li>
//                       <li>Static Testing</li>
//                       <li>Smoke Testing</li>
//                       <li>Functional Testing</li>
//                       <li>Regression Testing</li>
//                       <li>Back End Testing</li>
//                       <li>Black Box Testing</li>
//                       <li>White Box Testing</li>
//                       <li>Integration Testing</li>
//                       <li>System Testing</li>
//                       <li>User Acceptance Testing</li>
//                       <li>Performance Testing</li>
//                       <li>Security Testing</li>
//                     </ul>
//                   </li>
//                 </ul>

//                 <ul>
//                   <p className="fw-bold mt-5">SDLC Models:</p>
//                   <li>
//                     Discussion on SDLC Models (Waterfall Methodology and Agile
//                     Methodology).
//                     <ul>
//                       <li>Waterfall model Design</li>
//                       <li>
//                         Advantages and Disadvantages of the Waterfall model
//                       </li>
//                       <li>Agile model Design</li>
//                       <li>Advantages and Disadvantages of the Agile model</li>
//                     </ul>
//                   </li>
//                   <li>Introduction to Agile Scrum process.</li>
//                 </ul>

//                 <ul>
//                   <p className="fw-bold mt-5">
//                     Test Case and Defect Management:
//                   </p>
//                   <li>
//                     What is the Requirement? How to write a Requirement in an
//                     excel spreadsheet?
//                   </li>
//                   <li>
//                     What is User Story? How to write a User Story in an excel
//                     spreadsheet?
//                   </li>
//                   <li>
//                     What is a Test Case? How to write a Test case in an excel
//                     spreadsheet?
//                   </li>
//                   <li>What is RTM (Requirement Traceability Matrix)?</li>
//                   <li>
//                     Introduction to Defect management and Bugs Report.
//                     Introduction to Defect Life Cycle.
//                   </li>
//                 </ul>

//                 <ul>
//                   <p className="fw-bold mt-5"> JIRA Issue Management Tool:</p>
//                   <li>
//                     Introduction to JIRA issue management tool and Zephyr test
//                     management tool.
//                     <ul>
//                       <li>Create an epic</li>
//                       <li>Create a user story</li>
//                       <li>Create a test case</li>
//                       <li>Create a sprint</li>
//                       <li>
//                         Moving items from the product backlog to the sprint
//                         backlog
//                       </li>
//                       <li>Start a sprint</li>
//                       <li>
//                         The transition of a JIRA ticket in an active sprint
//                       </li>
//                       <li>Create a test cycle</li>
//                       <li>Execution of test case</li>
//                       <li>Create a defect</li>
//                     </ul>
//                   </li>
//                 </ul>

//                 <ul>
//                   <p className="fw-bold mt-5"> Exam:</p>
//                   <li>Exam (Written and Practical)</li>
//                   <li>Oral presentation test</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           {/* <div class="accordion-item">
//             <h2 class="accordion-header" id="headingThree">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseThree"
//                 aria-expanded="false"
//                 aria-controls="collapseThree"
//               >
//                 MODULE 3: Accessibility Testing
//               </button>
//             </h2>
//             <div
//               id="collapseThree"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingThree"
//               data-bs-parent="#accordionExample"
//             >
//               <div className="accordion-body text-white">
//                 <ul>
//                   <li>What is Accessibility Testing?</li>
//                   <li className="mt-2">
//                     Why it is important to perform Accessibility Testing?
//                   </li>
//                   <li className="mt-2">
//                     Which Disabilities to Support by Accessibility Testing?
//                     <ul>
//                       <li>Hearing/Auditory Disability</li>
//                       <li>Vision/Visual Disability</li>
//                       <li>Physical Disability</li>
//                       <li>Cognitive/Learning or Neurological Disability</li>
//                       <li>Literacy Disability</li>
//                       <li>Speech Disability</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     How to perform Accessibility Testing?
//                     <ul>
//                       <li>Manual Testing</li>
//                       <li>Automated Testing</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     What are the different types of testing tools of
//                     Accessibility Testing?
//                     <ul>
//                       <li>Axe digital accessibility toolkit</li>
//                       <li>WAVE</li>
//                       <li>JAWS</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Checklist and Design Requirements for Quality Assurance
//                   </li>
//                   <li className="mt-2">
//                     Writing manual test cases for Accessibility Testing
//                   </li>
//                   <li className="mt-2">
//                     Perform web accessibility testing using Axe digital
//                     accessibility toolkit
//                     <ul>
//                       <li>
//                         Configuration of the tool in the Chrome browser
//                         extension
//                       </li>
//                       <li>Perform testing on the entire pages</li>
//                       <li>Perform testing for page content and widgets</li>
//                       <li>Perform Test for Keyboard Accessibility</li>
//                       <li>Perform Test for Meaning Conveyed with Color</li>
//                       <li>Perform Test Alt Text Quality</li>
//                       <li>Perform Test Video/Audio Accessibility</li>
//                       <li>Perform Test for Landmarks</li>
//                       <li>Perform Test for Headings</li>
//                       <li>Perform Test Link Text Quality</li>
//                       <li>Perform Test Form Labels and Instructions</li>
//                       <li>Perform Test Form Validation</li>
//                       <li>Generate and export testing results</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Writing Effective Accessibility Bug Tickets
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div> */}

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingFour">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseFour"
//                 aria-expanded="false"
//                 aria-controls="collapseFour"
//               >
//                 MODULE 3: Database Testing
//               </button>
//             </h2>
//             <div
//               id="collapseFour"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingFour"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>What is Database?</li>
//                   <li className="mt-4">
//                     Types of Databases
//                     <ul>
//                       <li>Relational databases</li>
//                       <li>Relational databases</li>
//                       <li>Distributed databases</li>
//                       <li>Data warehouse</li>
//                       <li>NoSQL databases</li>
//                       <li>Graph databases</li>
//                       <li>OLTP databases</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Latest Database Technology Model
//                     <ul>
//                       <li>Open-source databases</li>
//                       <li>Cloud databases</li>
//                       <li>Multimodel database</li>
//                       <li>Document/JSON database</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">What is Database Management System?</li>
//                   <li className="mt-2">What is SQL?</li>
//                   <li className="mt-4">
//                     Types of SQL
//                     <ul>
//                       <li>Data Definition Language (DDL)</li>
//                       <li>Data Manipulation Language (DML)</li>
//                     </ul>
//                   </li>
//                   <li>What are the data types in SQL?</li>
//                   <li>Database Management tool – PostgreSQL</li>
//                   <li className="mt-4">
//                     SQL Practical
//                     <ul>
//                       <li>Create Database</li>
//                       <li>Connect to the Database</li>
//                       <li>Create table</li>
//                       <li>Primary keys</li>
//                       <li>Insert value into the table</li>
//                       <li>Select value from the table</li>

//                       <li>
//                         Aggregate functions
//                         <ul>
//                           <li>COUNT</li>
//                           <li>SUM</li>
//                           <li>MIN</li>
//                           <li>MAX</li>
//                           <li>AVG</li>
//                         </ul>
//                       </li>
//                       <li className="mt-2">Order By</li>
//                       <li>
//                         Join By
//                         <ul>
//                           <li>Inner Join</li>
//                           <li>Equi Join</li>
//                           <li>Left Outer Join</li>
//                           <li>Right Outer Join</li>
//                         </ul>
//                       </li>
//                       <li className="mt-2">Alter Table’s Column</li>
//                       <li>Update Table’s Record</li>
//                       <li>SQL Constraints</li>
//                       <li>Group By</li>
//                       <li>Having</li>
//                       <li>Like Operator</li>
//                       <li>Between Operator</li>
//                       <li>In Operator</li>
//                       <li>UNION</li>
//                       <li>INTERSECTION</li>
//                       <li>Difference between Union and Intersection</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">Exam (Written & Practical)</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingFive">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseFive"
//                 aria-expanded="false"
//                 aria-controls="collapseFive"
//               >
//                 MODULE 4: API Testing
//               </button>
//             </h2>
//             <div
//               id="collapseFive"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingFive"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>What is API Testing?</li>
//                   <li>Importance of API Testing.</li>
//                   <li>What is the REST protocol?</li>
//                   <li>CRUD HTTP methods.</li>
//                   <li className="mt-4">
//                     Installation and usages of API Testing tool Postman
//                     <ul>
//                       <li>Create Environment</li>
//                       <li>Create Variable</li>
//                       <li>Create GET Request and Validate Response</li>
//                       <li>Create POST Request and Validate Response</li>
//                       <li>Create PUT Request and Validate Response</li>
//                       <li>Create PATCH Request and Validate Response</li>
//                       <li>Create DELETE Request and Validate Response</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">Observe Live API call on a webpage.</li>
//                   <li>What is API Documentation?</li>
//                   <li className="mt-4">
//                     Develop Mock REST API using JSON Server
//                     <ul>
//                       <li>Installation of VS Code IDE</li>
//                       <li>Installation of Node Js</li>
//                       <li>Installation of JSON Server using npm </li>
//                       <li>Generate API endpoints using JSON Server</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">Exam (Written & Practical)</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSix">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseSix"
//                 aria-expanded="false"
//                 aria-controls="collapseSix"
//               >
//                 MODULE 5: Core Java
//               </button>
//             </h2>
//             <div
//               id="collapseSix"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSix"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>What is OOP?</li>
//                   <li>What is a Class?</li>
//                   <li>What is an Object?</li>
//                   <li>What are the 4 OOP Principles?</li>
//                   <li>Create a Java project in IntelliJ</li>
//                   <li>Create a Java package</li>
//                   <li>Create a Java class</li>
//                   <li>Write the first java program to display “Hello World”</li>
//                   <li>Data Types in Java</li>
//                   <li>
//                     What is the method? What are the types of methods in Java?
//                   </li>
//                   <li>What is a variable in Java? Variable scopes in Java.</li>
//                   <li>What are the method parameters and method arguments?</li>
//                   <li className="mt-4">
//                     Access modifier in Java
//                     <ul>
//                       <li>Default</li>
//                       <li>Private</li>
//                       <li>Protected</li>
//                       <li>Public</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">Operators in Java</li>
//                   <li>Loops in Java</li>
//                   <li>Arrays in Java</li>
//                   <li>Split Method in Array</li>
//                   <li>List in Java</li>
//                   <li>Convert Array to List</li>
//                   <li>Convert List to Array</li>
//                   <li className="mt-4">
//                     Conditional Statements in Java
//                     <ul>
//                       <li>If Statement</li>
//                       <li>If Else Statement</li>
//                       <li>Nested If Statement</li>
//                       <li>Switch Statement</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">Conditional Operators in Java</li>
//                   <li>String Methods</li>
//                   <li>Hash Maps in Java</li>
//                   <li>Read data from the terminal in Java</li>
//                   <li>Constructors in Java</li>
//                   <li>Recursions in Java</li>
//                   <li>Exceptions in Java</li>
//                   <li>this keyword in Java</li>
//                   <li>Inheritance in Java</li>
//                   <li>Polymorphism in Java</li>
//                   <li>Encapsulation in Java</li>
//                   <li>Abstraction in Java</li>
//                   <li>Interface in Java</li>
//                   <li>Enum in Java</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseSeven"
//                 aria-expanded="false"
//                 aria-controls="collapseSeven"
//               >
//                 MODULE 6: Automation Testing
//               </button>
//             </h2>
//             <div
//               id="collapseSeven"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Test Automation
//                     <ul>
//                       <li>What is Test Automation?</li>
//                       <li>Importance of Test Automation.</li>
//                       <li>Which Test Cases to Automate?</li>
//                       <li>Describe the Test Automation Process.</li>
//                       <li>Criteria to select the automation tool.</li>
//                       <li>What is Selenium?</li>
//                       <li>Describe Selenium Components.</li>
//                       <li>Advantages and Disadvantages of Selenium.</li>
//                       <li>What is Maven?</li>
//                       <li>Add required dependency into the pom.xml file.</li>
//                       <li>Describe some popular testing frameworks in java.</li>
//                       <li>
//                         Create a first automation script to validate a website
//                         title.
//                       </li>
//                       <li>Discussion on selenium locators.</li>
//                       <li>
//                         Automate a simple website using different locators.
//                       </li>
//                       <li>Optimize the code using the “By” selenium class.</li>
//                       <li>Wait time in Selenium</li>
//                       <li>Difference between quite() and close()</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Automation Framework using Page Object Model:
//                     <ul>
//                       <li>What is the Test Automation Framework?</li>
//                       <li>What is the Page Object Model?</li>
//                       <li>
//                         Develop a Test Automation Framework using the Page
//                         Object Model.
//                       </li>
//                       <li>Execute automation test using testng.xml.</li>
//                       <li>Usage of Logger (Log4j) in Java.</li>
//                     </ul>
//                   </li>

//                   <li className="mt-4">
//                     Data-Driven Testing using Database:
//                     <ul>
//                       <li>
//                         Established a JDBC connection between the PostgreSQL and
//                         Automation Scripts
//                       </li>
//                       <li>
//                         Store all the required input data inside the database
//                       </li>
//                       <li>Discussion on Data Parameterizations.</li>
//                       <li>
//                         Execute automation test using the data-driven from
//                         Database.
//                       </li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     GitHub source control/version control tool:
//                     <ul>
//                       <li>What is Git?</li>
//                       <li>What is GitHub?</li>
//                       <li>Usages of Git Client tool – Girkraken.</li>
//                       <li>Create a GitHub Repository.</li>
//                       <li>Cloning a GitHub Repository.</li>
//                       <li>Committing work in GitHub.</li>
//                       <li>Push the work in GitHub.</li>
//                       <li>Setting up Branch Rules in GitHub</li>
//                       <li>Creating a local private branch</li>
//                       <li>Staged the work into the private branch</li>
//                       <li>Push the local private brunch into Origin</li>
//                       <li>Create a Pull Request</li>
//                       <li>Merge Pull Request</li>
//                       <li>Delete private branch from Origin</li>
//                       <li>Update local master branch</li>
//                       <li>Delete local private branch</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Rest Assured:
//                     <ul>
//                       <li>What is Rest Assured?</li>
//                       <li>Configure Rest Assured with Java Project.</li>
//                       <li>Automate API tests using Rest Assured.</li>
//                       <li>Validate the Response Code.</li>
//                       <li>Validate Response Body.</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     BDD framework using Cucumber:
//                     <ul>
//                       <li>Create a new maven project</li>
//                       <li>
//                         Add maven dependencies (Cucumber Java, Cucumber Junit,
//                         Junit, Selenium Java, Webdriver manager)
//                       </li>
//                       <li>
//                         Create a folder Features under src/test/resources (All
//                         feature file should be located)
//                       </li>
//                       <li>Add gherkin extension in the IDE</li>
//                       <li>Create feature file and add contents</li>
//                       <li>Explain Gherkin Syntax in the feature file.</li>
//                       <li>Run the feature file and observe the error</li>
//                       <li>Add step definitions under src/test/java package</li>
//                       <li>Create a runner class.</li>
//                       <li>Add cucumber options for generating reports</li>
//                       <li>Verify test results</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     CI/CD using Jenkins:
//                     <ul>
//                       <li>Discussion on Deployment Environments</li>
//                       <li>Build and Deployment Workflow</li>
//                       <li>What is Jenkins?</li>
//                       <li>Download and Configure Jenkins.</li>
//                       <li>Installed all the required plugins in Jenkins.</li>
//                       <li>Create a job to execute automation test</li>
//                       <li>Pulling code from GitHub and Execute from Jenkins</li>
//                       <li>Analyzing the test execution results.</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Selenium Grid:
//                     <ul>
//                       <li>What is Selenium Grid?</li>
//                       <li>What is Docker?</li>
//                       <li>Installation of Docker</li>
//                       <li>Create docker file for Selenium Grid</li>
//                       <li>
//                         Execute test in docker in a different browser like
//                         Chrome, Firefox
//                       </li>
//                       <li>What is Makefile?</li>
//                       <li>Install Makefile in Windows</li>
//                       <li>Create Makefile</li>
//                       <li>Execute test from Makefile</li>
//                     </ul>
//                   </li>
//                   <li className="mt-4">
//                     Amazon Web Services(AWS):
//                     <ul>
//                       <li>Creation of AWS Free Tier account</li>
//                       <li>Creation of EC2 instance</li>
//                       <li>
//                         Installation of required software inside EC2 instance
//                       </li>
//                       <li>Configure Jenkins inside EC2 instance</li>
//                       <li>
//                         Configure Selenium Grid using docker inside EC2 instance
//                       </li>
//                       <li>
//                         Execute automation tests inside EC2 instance in a
//                         distributed cloud environment
//                       </li>
//                     </ul>
//                   </li>
//                   {/* <li className="mt-4">
//                     Mobile Automation:
//                     <ul>
//                       <li>
//                         Installation of Appium Server Desktop Client and CLI in
//                         Mac and Windows platform
//                       </li>
//                       <li>
//                         Installation of Android Studio in Mac and Windows
//                         platform
//                       </li>
//                       <li>
//                         Creation of Android Emulator for mobile automation
//                       </li>
//                       <li>Usages of Appium Inspector</li>
//                       <li>
//                         Overview of Desired Capabilities and start a driver
//                         session using Appium Inspector
//                       </li>
//                       <li>
//                         Start a driver session programmatically using Appium
//                         Java client
//                       </li>
//                       <li>
//                         Develop a mobile automation framework using BDD cucumber
//                       </li>
//                     </ul>
//                   </li> */}
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col">
//               <a
//                 class="face-button"
//                 href="/files/Brochure.pdf"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <div class="face-primary">
//                   <span class="icon fa fa-download"></span>
//                   Course Details
//                 </div>

//                 <div class="face-secondary">
//                   <span class="icon fa fa-hdd-o"></span>
//                   Size: 4.80 MB
//                 </div>
//               </a>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Accordion end */}

//       {/* Price start */}

//       <section id="price" className="pricing">
//         <div className="container">
//           <div className="section-title">
//             <h2>Course Fee</h2>
//           </div>
//           <div className="row">
//             <div className="col-lg-4 offset-md-4">
//               <div className="card mb-5 mb-lg-0">
//                 <div className="card-body">
//                   <h5 className="card-title text-muted text-uppercase text-center">
//                     Price
//                   </h5>
//                   {/* <h6 className="card-price text-center">
//                     $3500
//                     <span className="period">One Time</span>
//                   </h6> */}
//                   <hr />

//                   <h5 className="card-title text-muted text-uppercase text-center">
//                     <a style={{ textDecoration: "none" }} href="/contact-us">
//                       Call Us
//                     </a>{" "}
//                     For Course Fees
//                   </h5>
//                   {/* <h6 className="card-price text-center">
//                     $3000
//                     <span className="period">One Time</span>
//                   </h6> */}
//                   <hr />
//                   <ul className="fa-ul">
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       Lectures & Exams
//                       {/* <strong>Lectures & Exams</strong> */}
//                     </li>
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       MCQ Quizes
//                     </li>
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       Practical
//                     </li>
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       Question Answering
//                     </li>
//                   </ul>
//                   <div className="d-grid">
//                     <a
//                       href="/registration"
//                       className="btn btn-primary text-uppercase"
//                     >
//                       Enroll
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Price end */}

//       {/* table start */}
//       <section>
//         <div class="container">
//           <div className="section-title">
//             <h2>Current Course Schedule</h2>
//           </div>
//           <p>
//             <strong>Weekend Classes: </strong>Saturday & Sunday, 10 am – 2 pm |
//             New York Time
//             <br></br>
//             {/* <strong>Weekdays Classes: </strong>Monday, Wednesday & Friday, 6.30
//             pm – 9.30 pm | New York Time<br></br> */}
//             <strong>Student Support Session: </strong>Thursday, 9 pm – 10 pm |
//             New York Time
//             <br></br>
//           </p>
//           <table class="table text-center">
//             <thead>
//               <tr>
//                 <th scope="col"></th>
//                 <th scope="col">Time</th>
//                 <th scope="col">Date (Weekend)</th>
//                 {/* <th scope="col">Date (Weekday)</th> */}
//                 <th scope="col">Modules</th>
//               </tr>
//             </thead>
//             <tbody>
//               {
//                 courseDetails.weeks.map((week) => (
//                   <tr key={week.week}>
//                     <th scope="row"></th>
//                     <td>{week.week} ({courseDetails.class_duraton})</td>
//                     <td>
//                       {week.date_01}<br></br>
//                       {week.date_02}
//                     </td>
//                     <td>{week.module}</td>
//                   </tr>
//                 ))
//               }
//               {/* <tr>
//                 <th scope="row"></th>
//                 <td>Week 1 ( Hours)</td>
//                 <td>
//                   06/10/2023 (Sat)<br></br>
//                   06/11/2023 (Sun)
//                 </td>
//                 <td>Basic Computing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 2 (8 Hours)</td>
//                 <td>
//                   06/17/2023 (Sat)<br></br>
//                   06/18/2023 (Sun)
//                 </td>
//                 <td>Manual Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 3 (8 Hours)</td>
//                 <td>
//                   06/24/2023 (Sat)<br></br>
//                   06/25/2023 (Sun)
//                 </td>
//                 <td>Manual Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 4 (8 Hours)</td>
//                 <td>
//                   07/01/2023 (Sat)<br></br>
//                   07/02/2023 (Sun)
//                 </td>
//                 <td>Database Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 5 (8 Hours)</td>
//                 <td>
//                   07/08/2023 (Sat)<br></br>
//                   07/09/2023 (Sun)
//                 </td>
//                 <td>API Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 6 (8 Hours)</td>
//                 <td>
//                   07/15/2023 (Sat)<br></br>
//                   07/16/2023 (Sun)
//                 </td>
//                 <td>Core Java</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 7 (8 Hours)</td>
//                 <td>
//                   07/22/2023 (Sat)<br></br>
//                   07/23/2023 (Sun)
//                 </td>
//                 <td>Core Java</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 8 (8 Hours)</td>
//                 <td>
//                   07/29/2023 (Sat)<br></br>
//                   07/30/2023 (Sun)
//                 </td>
//                 <td>Automation Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 9 (8 Hours)</td>
//                 <td>
//                   08/05/2023 (Sat)<br></br>
//                   08/06/2023 (Sun)
//                 </td>
//                 <td>Automation Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 10 (8 Hours)</td>
//                 <td>
//                   08/12/2023 (Sat)<br></br>
//                   08/13/2023 (Sun)
//                 </td>
//                 <td>Automation Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 11 (8 Hours)</td>
//                 <td>
//                   08/19/2023 (Sat)<br></br>
//                   08/20/2023 (Sun)
//                 </td>
//                 <td>Automation Testing</td>
//               </tr>
//               <tr>
//                 <th scope="row"></th>
//                 <td>Week 12 (8 Hours)</td>
//                 <td>
//                   08/26/2023 (Sat)<br></br>
//                   08/27/2023 (Sun)
//                 </td>
//                 <td>Automation Testing</td>
//               </tr> */}
//             </tbody>
//           </table>
//         </div>
//       </section>

//       {/* table end */}

//       {/* tools start */}

//       <section>
//         <div className="container">
//           <div className="section-title">
//             <h2>Tools</h2>
//           </div>
//           <div className="row my-4">
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fab fa-jira mt-3"></i>
//                   </h2>
//                   <h5 className="card-title">Atlassian JIRA</h5>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-database mt-3"></i>
//                   </h2>
//                   <h5 className="card-title">POSTGRESQL</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-cog mt-3"></i>
//                   </h2>
//                   <h5 className="card-title">MAVEN</h5>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row  my-4">
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-file-excel mt-3"></i>
//                   </h2>
//                   <h5 className="card-title">Excel</h5>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fab fa-node mt-3"></i>
//                   </h2>
//                   <h5 className="card-title">NODE JS</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fab fa-github"></i>
//                   </h2>
//                   <h5 className="card-title">GITHUB</h5>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row  my-4">
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-code-branch"></i>
//                   </h2>
//                   <h5 className="card-title">INTELLIJ IDE</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fab fa-java"></i>
//                   </h2>
//                   <h5 className="card-title">JAVA</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-grip-lines"></i>
//                   </h2>
//                   <h5 className="card-title">REST ASSURED</h5>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row  my-4">
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fab fa-java"></i>
//                   </h2>
//                   <h5 className="card-title">TESTNG</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-server"></i>
//                   </h2>
//                   <h5 className="card-title">JSON SERVER</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-envelope-open"></i>
//                   </h2>
//                   <h5 className="card-title">POSTMAN</h5>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row  my-4">
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-tasks"></i>
//                   </h2>
//                   <h5 className="card-title">CUCUMBER</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-genderless"></i>
//                   </h2>
//                   <h5 className="card-title">SMARTBEAR ZEPHYR</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fas fa-comment-alt"></i>
//                   </h2>
//                   <h5 className="card-title">SELENIUM</h5>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* tools end */}
//       <Footer />
//     </>
//   );
// }

// export default SoftwareTestingCourse;


import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Tabs, Tab } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import jsonData from './local_api/accessibility.json';

import "aos/dist/aos.css";

import "./BasicComputingCourse.css";
import ShajibKhadem from "./assets/Shajib-khadem.jpg";
import RifatHalim from "./assets/Rifat-Halim.JPG";
import FaridUddin from "./assets/Farid-Uddin.jpeg";
import { useEffect, useState } from "react";
import { getCourseWeeks, getcourses } from "./local_api/ApiService";

function SoftwareTestingCourse() {
  AOS.init();

  // data fetching function for localapi
  const id = 5;

  const [courseDetails, setCourseDetails] = useState(null);
  const [courseWeeks, setCourseWeeks] = useState([]);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const courses = await getcourses();
        const course = courses.find((course) => course.id === parseInt(id));

        if (course) {
          setCourseDetails(course);

          // Fetch weeks data for the course from ApiService
          const weeks = await getCourseWeeks(id);
          setCourseWeeks(weeks);
        } else {
          setError("Course not found");
        }
      } catch (error) {
        setError("Error fetching data");
      }
    };

    fetchData();
  }, []);

  console.log('courses', courseDetails);
  console.log('weeks', courseWeeks);


  const sortWeeks = (weeks) => {
    return weeks.slice().sort((a, b) => {
      const weekA = a.week.toLowerCase();
      const weekB = b.week.toLowerCase();
      return weekA.localeCompare(weekB, undefined, { numeric: true, sensitivity: 'base' });
    });
  };

  return (
    <>
      <Navbar />

      {/* Slider start */}

      <section className="bgimage">
        <div className="container">
          <div className="row par">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
              <h1>{courseDetails?.course_name}</h1>
              <br />
              <p>
                {courseDetails?.course_description}
              </p>
              <h3 className="mb-3">Upcoming Batch Details</h3>
              <p>
                Main Course: {courseDetails?.start_date}
                <br />
                Trial Class: {courseDetails?.trial_date}
              </p>
              <p>
                <a href="#price" className="btn btn-primary btn-large">
                  Course Fee »
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Slider end */}

      {/* course info start */}
      <section>
        <div id="parallax-world-of-ugg">
          <div className="container">
            <div className="section-title text-center">
              <h2>Course Overview</h2>
            </div>
            <div className="block">
              <p>
                Manual Testing is a type of Software Testing where Testers
                manually test the software application. Manual Testing is the
                most primitive of all testing types and helps find bugs in the
                software system. JIRA is an issue management tool and it helps
                to facilitate the testing process and to manage various types of
                issues in the software development process. Manual Testing does
                not require knowledge of any testing tool.<br></br>
                <br></br>
                Testing with SQL is also known as Database Testing. The data
                entered in the front end will be stored in the back-end
                database. The database may be Oracle, PostgreSQL, etc. The data
                will be organized in the tables as a record, and it is used to
                support the content of the page. Database or backend testing is
                important because if it is not done it has some serious
                complications like deadlock, data corruption, data loss, etc.
                <br></br>
                <br></br>
                Application Programming Interface (API) enables communication
                and data exchange between two separate software systems an
                example is a front end and the backend database. API Testing is
                a software testing type that validates APIs. The purpose of API
                Testing is to check the functionality, reliability, performance,
                and security of the programming interfaces.<br></br>
                <br></br>
                Automation Testing means using an automation tool to test the
                software application. On the contrary, Manual Testing is
                performed by a human sitting in front of a computer carefully
                executing the test steps. Selenium is an open-source automation
                tool that is currently most used all over the world and it
                supports various programming languages such as Java, Python, C#,
                Ruby, etc.
              </p>
              {/* <p className="line-break margin-top-10" />
              <p className="margin-top-10">
                This course will provide complete guidance on how to provide
                technical assistance and support related to IT systems, hardware
                & software solutions as well as to manage customer expectations
                with professionalism in a timely manner.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* course info end */}

      {/* Tab start */}

      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-12">
            <section className="payment">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="tab-menu"
              >
                <Tab
                  className="tab-item tabcourse"
                  eventKey="home"
                  title="Course Information"
                >
                  <ul>
                    <li>
                      Weekend Classes: {courseDetails?.weekend_classes} | New
                      York Time
                    </li>

                    <li>
                      Student Support Session: {courseDetails?.student_support_session} |
                      New York Time
                    </li>
                    <li>Start Date: {courseDetails?.start_date} (Weekend Batch)</li>

                    <li>
                      Duration: {courseDetails?.course_duration}.
                      <ul>
                        <li>Regular Class: {courseDetails?.total_class_duration}</li>
                        <li>Student Support Session: {courseDetails?.support_session_duration}</li>
                      </ul>
                    </li>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="profile"
                  title="Who Can Join"
                >
                  <ul>
                    <li>Bachelor’s degree from any discipline</li>
                    <li>Proficiency in English will be a huge plus</li>
                  </ul>
                </Tab>
                {/* <Tab
                  className="tab-item tabcourse"
                  eventKey="prerequisites"
                  title="Prerequisites"
                >
                  <ul>
                    <li>
                      A motivated person with a Bachelor degree in any
                      discipline who is seeking an IT professional job.
                    </li>
                  </ul>
                </Tab> */}
                <Tab
                  className="tab-item tabcourse"
                  eventKey="contact"
                  title="Learning Outcome"
                >
                  <ul>
                    <li>
                      Students will be capable of performing manual & automation
                      testing in software development companies.
                    </li>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="check"
                  title="Instruction Method"
                >
                  <ul>
                    <li>Instructor led (Online only)</li>
                  </ul>
                </Tab>
              </Tabs>
            </section>
          </div>
        </div>
      </div>

      {/* Tab end */}

      {/* Accordion start */}

      <section>
        <div class="accordion container" id="accordionExample">
          <div className="section-title text-center">
            <h2>Course Outline</h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                MODULE 1: Basic Computing
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <p className="fw-bold">Computer Fundamentals:</p>
                  <li>Computer Architecture including hardware and software</li>
                  <li>Operating Systems Basics (Windows, macOS)</li>
                  <li>Managing files in different Operating Systems</li>
                  <li>Remote Desktop, Internet, VPN, Proxy Server</li>
                  <li>Office Communication Tool such as Slack</li>
                </ul>

                <ul>
                  <p className="mt-5 fw-bold">
                    Google Suite/Microsoft (MS) Office:
                  </p>
                  <li>Google Docs/MS Word, Google Sheets/MS Excel</li>
                  <li>Google Slides /MS PowerPoint, Gmail/MS Outlook</li>
                </ul>

                <ul>
                  <p className="mt-5 fw-bold">
                    Networking and Customer Service:
                  </p>
                  <li>Networking Concepts, IP Addressing, DHCP</li>
                  <li>Networking Commands such as ipconfig, ping, nslookup</li>
                  <li>Linux Operating System Basics and Installation Guide</li>
                  <li>Linux Commands such as pwd, mkdir, touch</li>
                  <li>
                    Customer Service Principles, Problem Solving, Effective
                    Communication
                  </li>
                  <li>CRM software such as HubSpot, JIRA</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                MODULE 2: Manual Testing
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <p className="fw-bold">Software Testing and SDLC:</p>
                  <li>
                    What is Software? Discussion on the importance of Software
                    in our daily life.
                  </li>
                  <li>
                    What is Software Testing? What are the principles of
                    Software Testing?
                  </li>
                  <li>Software Testing Trends and Future Perspective</li>
                  <li>
                    Introduction to SDLC(Software Development Lifecycle).
                    Discussion on the different phases of SDLC
                    <ul>
                      <li>Planning & Requirement Analysis</li>
                      <li>Defining Requirements</li>
                      <li>Designing the product</li>
                      <li>Building or Developing the product</li>
                      <li>Testing</li>
                      <li>Deployment & Maintenance</li>
                    </ul>
                  </li>
                  <li>Why testing is a crucial part of SDLC?</li>
                </ul>

                <ul>
                  <p className="fw-bold mt-5">Various kind of Testing:</p>
                  <li>
                    Discussion of various kinds of Software Testing.
                    <ul>
                      <li>Dynamic Testing</li>
                      <li>Static Testing</li>
                      <li>Smoke Testing</li>
                      <li>Functional Testing</li>
                      <li>Regression Testing</li>
                      <li>Back End Testing</li>
                      <li>Black Box Testing</li>
                      <li>White Box Testing</li>
                      <li>Integration Testing</li>
                      <li>System Testing</li>
                      <li>User Acceptance Testing</li>
                      <li>Performance Testing</li>
                      <li>Security Testing</li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <p className="fw-bold mt-5">SDLC Models:</p>
                  <li>
                    Discussion on SDLC Models (Waterfall Methodology and Agile
                    Methodology).
                    <ul>
                      <li>Waterfall model Design</li>
                      <li>
                        Advantages and Disadvantages of the Waterfall model
                      </li>
                      <li>Agile model Design</li>
                      <li>Advantages and Disadvantages of the Agile model</li>
                    </ul>
                  </li>
                  <li>Introduction to Agile Scrum process.</li>
                </ul>

                <ul>
                  <p className="fw-bold mt-5">
                    Test Case and Defect Management:
                  </p>
                  <li>
                    What is the Requirement? How to write a Requirement in an
                    excel spreadsheet?
                  </li>
                  <li>
                    What is User Story? How to write a User Story in an excel
                    spreadsheet?
                  </li>
                  <li>
                    What is a Test Case? How to write a Test case in an excel
                    spreadsheet?
                  </li>
                  <li>What is RTM (Requirement Traceability Matrix)?</li>
                  <li>
                    Introduction to Defect management and Bugs Report.
                    Introduction to Defect Life Cycle.
                  </li>
                </ul>

                <ul>
                  <p className="fw-bold mt-5"> JIRA Issue Management Tool:</p>
                  <li>
                    Introduction to JIRA issue management tool and Zephyr test
                    management tool.
                    <ul>
                      <li>Create an epic</li>
                      <li>Create a user story</li>
                      <li>Create a test case</li>
                      <li>Create a sprint</li>
                      <li>
                        Moving items from the product backlog to the sprint
                        backlog
                      </li>
                      <li>Start a sprint</li>
                      <li>
                        The transition of a JIRA ticket in an active sprint
                      </li>
                      <li>Create a test cycle</li>
                      <li>Execution of test case</li>
                      <li>Create a defect</li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <p className="fw-bold mt-5"> Exam:</p>
                  <li>Exam (Written and Practical)</li>
                  <li>Oral presentation test</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                MODULE 3: Database Testing
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is Database?</li>
                  <li className="mt-4">
                    Types of Databases
                    <ul>
                      <li>Relational databases</li>
                      <li>Relational databases</li>
                      <li>Distributed databases</li>
                      <li>Data warehouse</li>
                      <li>NoSQL databases</li>
                      <li>Graph databases</li>
                      <li>OLTP databases</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    Latest Database Technology Model
                    <ul>
                      <li>Open-source databases</li>
                      <li>Cloud databases</li>
                      <li>Multimodel database</li>
                      <li>Document/JSON database</li>
                    </ul>
                  </li>
                  <li className="mt-4">What is Database Management System?</li>
                  <li className="mt-2">What is SQL?</li>
                  <li className="mt-4">
                    Types of SQL
                    <ul>
                      <li>Data Definition Language (DDL)</li>
                      <li>Data Manipulation Language (DML)</li>
                    </ul>
                  </li>
                  <li>What are the data types in SQL?</li>
                  <li>Database Management tool – PostgreSQL</li>
                  <li className="mt-4">
                    SQL Practical
                    <ul>
                      <li>Create Database</li>
                      <li>Connect to the Database</li>
                      <li>Create table</li>
                      <li>Primary keys</li>
                      <li>Insert value into the table</li>
                      <li>Select value from the table</li>

                      <li>
                        Aggregate functions
                        <ul>
                          <li>COUNT</li>
                          <li>SUM</li>
                          <li>MIN</li>
                          <li>MAX</li>
                          <li>AVG</li>
                        </ul>
                      </li>
                      <li className="mt-2">Order By</li>
                      <li>
                        Join By
                        <ul>
                          <li>Inner Join</li>
                          <li>Equi Join</li>
                          <li>Left Outer Join</li>
                          <li>Right Outer Join</li>
                        </ul>
                      </li>
                      <li className="mt-2">Alter Table’s Column</li>
                      <li>Update Table’s Record</li>
                      <li>SQL Constraints</li>
                      <li>Group By</li>
                      <li>Having</li>
                      <li>Like Operator</li>
                      <li>Between Operator</li>
                      <li>In Operator</li>
                      <li>UNION</li>
                      <li>INTERSECTION</li>
                      <li>Difference between Union and Intersection</li>
                    </ul>
                  </li>
                  <li className="mt-4">Exam (Written & Practical)</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                MODULE 4: API Testing
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is API Testing?</li>
                  <li>Importance of API Testing.</li>
                  <li>What is the REST protocol?</li>
                  <li>CRUD HTTP methods.</li>
                  <li className="mt-4">
                    Installation and usages of API Testing tool Postman
                    <ul>
                      <li>Create Environment</li>
                      <li>Create Variable</li>
                      <li>Create GET Request and Validate Response</li>
                      <li>Create POST Request and Validate Response</li>
                      <li>Create PUT Request and Validate Response</li>
                      <li>Create PATCH Request and Validate Response</li>
                      <li>Create DELETE Request and Validate Response</li>
                    </ul>
                  </li>
                  <li className="mt-4">Observe Live API call on a webpage.</li>
                  <li>What is API Documentation?</li>
                  <li className="mt-4">
                    Develop Mock REST API using JSON Server
                    <ul>
                      <li>Installation of VS Code IDE</li>
                      <li>Installation of Node Js</li>
                      <li>Installation of JSON Server using npm </li>
                      <li>Generate API endpoints using JSON Server</li>
                    </ul>
                  </li>
                  <li className="mt-4">Exam (Written & Practical)</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                MODULE 5: Core Java
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is OOP?</li>
                  <li>What is a Class?</li>
                  <li>What is an Object?</li>
                  <li>What are the 4 OOP Principles?</li>
                  <li>Create a Java project in IntelliJ</li>
                  <li>Create a Java package</li>
                  <li>Create a Java class</li>
                  <li>Write the first java program to display “Hello World”</li>
                  <li>Data Types in Java</li>
                  <li>
                    What is the method? What are the types of methods in Java?
                  </li>
                  <li>What is a variable in Java? Variable scopes in Java.</li>
                  <li>What are the method parameters and method arguments?</li>
                  <li className="mt-4">
                    Access modifier in Java
                    <ul>
                      <li>Default</li>
                      <li>Private</li>
                      <li>Protected</li>
                      <li>Public</li>
                    </ul>
                  </li>
                  <li className="mt-4">Operators in Java</li>
                  <li>Loops in Java</li>
                  <li>Arrays in Java</li>
                  <li>Split Method in Array</li>
                  <li>List in Java</li>
                  <li>Convert Array to List</li>
                  <li>Convert List to Array</li>
                  <li className="mt-4">
                    Conditional Statements in Java
                    <ul>
                      <li>If Statement</li>
                      <li>If Else Statement</li>
                      <li>Nested If Statement</li>
                      <li>Switch Statement</li>
                    </ul>
                  </li>
                  <li className="mt-4">Conditional Operators in Java</li>
                  <li>String Methods</li>
                  <li>Hash Maps in Java</li>
                  <li>Read data from the terminal in Java</li>
                  <li>Constructors in Java</li>
                  <li>Recursions in Java</li>
                  <li>Exceptions in Java</li>
                  <li>this keyword in Java</li>
                  <li>Inheritance in Java</li>
                  <li>Polymorphism in Java</li>
                  <li>Encapsulation in Java</li>
                  <li>Abstraction in Java</li>
                  <li>Interface in Java</li>
                  <li>Enum in Java</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                MODULE 6: Automation Testing
              </button>
            </h2>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Test Automation
                    <ul>
                      <li>What is Test Automation?</li>
                      <li>Importance of Test Automation.</li>
                      <li>Which Test Cases to Automate?</li>
                      <li>Describe the Test Automation Process.</li>
                      <li>Criteria to select the automation tool.</li>
                      <li>What is Selenium?</li>
                      <li>Describe Selenium Components.</li>
                      <li>Advantages and Disadvantages of Selenium.</li>
                      <li>What is Maven?</li>
                      <li>Add required dependency into the pom.xml file.</li>
                      <li>Describe some popular testing frameworks in java.</li>
                      <li>
                        Create a first automation script to validate a website
                        title.
                      </li>
                      <li>Discussion on selenium locators.</li>
                      <li>
                        Automate a simple website using different locators.
                      </li>
                      <li>Optimize the code using the “By” selenium class.</li>
                      <li>Wait time in Selenium</li>
                      <li>Difference between quite() and close()</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    Automation Framework using Page Object Model:
                    <ul>
                      <li>What is the Test Automation Framework?</li>
                      <li>What is the Page Object Model?</li>
                      <li>
                        Develop a Test Automation Framework using the Page
                        Object Model.
                      </li>
                      <li>Execute automation test using testng.xml.</li>
                      <li>Usage of Logger (Log4j) in Java.</li>
                    </ul>
                  </li>

                  <li className="mt-4">
                    Data-Driven Testing using Database:
                    <ul>
                      <li>
                        Established a JDBC connection between the PostgreSQL and
                        Automation Scripts
                      </li>
                      <li>
                        Store all the required input data inside the database
                      </li>
                      <li>Discussion on Data Parameterizations.</li>
                      <li>
                        Execute automation test using the data-driven from
                        Database.
                      </li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    GitHub source control/version control tool:
                    <ul>
                      <li>What is Git?</li>
                      <li>What is GitHub?</li>
                      <li>Usages of Git Client tool – Girkraken.</li>
                      <li>Create a GitHub Repository.</li>
                      <li>Cloning a GitHub Repository.</li>
                      <li>Committing work in GitHub.</li>
                      <li>Push the work in GitHub.</li>
                      <li>Setting up Branch Rules in GitHub</li>
                      <li>Creating a local private branch</li>
                      <li>Staged the work into the private branch</li>
                      <li>Push the local private brunch into Origin</li>
                      <li>Create a Pull Request</li>
                      <li>Merge Pull Request</li>
                      <li>Delete private branch from Origin</li>
                      <li>Update local master branch</li>
                      <li>Delete local private branch</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    Rest Assured:
                    <ul>
                      <li>What is Rest Assured?</li>
                      <li>Configure Rest Assured with Java Project.</li>
                      <li>Automate API tests using Rest Assured.</li>
                      <li>Validate the Response Code.</li>
                      <li>Validate Response Body.</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    BDD framework using Cucumber:
                    <ul>
                      <li>Create a new maven project</li>
                      <li>
                        Add maven dependencies (Cucumber Java, Cucumber Junit,
                        Junit, Selenium Java, Webdriver manager)
                      </li>
                      <li>
                        Create a folder Features under src/test/resources (All
                        feature file should be located)
                      </li>
                      <li>Add gherkin extension in the IDE</li>
                      <li>Create feature file and add contents</li>
                      <li>Explain Gherkin Syntax in the feature file.</li>
                      <li>Run the feature file and observe the error</li>
                      <li>Add step definitions under src/test/java package</li>
                      <li>Create a runner class.</li>
                      <li>Add cucumber options for generating reports</li>
                      <li>Verify test results</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    CI/CD using Jenkins:
                    <ul>
                      <li>Discussion on Deployment Environments</li>
                      <li>Build and Deployment Workflow</li>
                      <li>What is Jenkins?</li>
                      <li>Download and Configure Jenkins.</li>
                      <li>Installed all the required plugins in Jenkins.</li>
                      <li>Create a job to execute automation test</li>
                      <li>Pulling code from GitHub and Execute from Jenkins</li>
                      <li>Analyzing the test execution results.</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    Selenium Grid:
                    <ul>
                      <li>What is Selenium Grid?</li>
                      <li>What is Docker?</li>
                      <li>Installation of Docker</li>
                      <li>Create docker file for Selenium Grid</li>
                      <li>
                        Execute test in docker in a different browser like
                        Chrome, Firefox
                      </li>
                      <li>What is Makefile?</li>
                      <li>Install Makefile in Windows</li>
                      <li>Create Makefile</li>
                      <li>Execute test from Makefile</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    Amazon Web Services(AWS):
                    <ul>
                      <li>Creation of AWS Free Tier account</li>
                      <li>Creation of EC2 instance</li>
                      <li>
                        Installation of required software inside EC2 instance
                      </li>
                      <li>Configure Jenkins inside EC2 instance</li>
                      <li>
                        Configure Selenium Grid using docker inside EC2 instance
                      </li>
                      <li>
                        Execute automation tests inside EC2 instance in a
                        distributed cloud environment
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingEight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                MODULE 7: Performance Testing
              </button>
            </h2>
            <div
              id="collapseEight"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Introduction to Performance Testing
                    <ul>
                      <li>What is performance testing?</li>
                      <li>Importance of performance testing.</li>
                    </ul>
                  </li>
                  <li className="mt-4">
                    Types of performance testing:
                    <ul>
                      <li>Introduction to Load Testing.</li>
                      <li>Introduction to Stress Testing</li>
                    </ul>
                  </li>

                  <li className="mt-4">
                    Important performing testing terminologies such as Connection Time, Average latency, Peak response time, Key Performance Indicators (KPI)
                  </li>
                  <li className="mt-4">
                    Performance testing with JMeter
                    <ul>
                      <li>Installation and Configuration of JMeter</li>
                      <li>Usages of various components of JMeter</li>
                      <ul>
                        <li>HTTP Request Defaults</li>
                        <li>User Defined Variables</li>
                        <li>Thread Group</li>
                        <li>HTTP(S) Test Script Recorder</li>
                        <li>Recording Controller</li>
                        <li>Response Assertion</li>
                        <li>HTTP Header Manager</li>
                        <li>View Results Tree</li>
                        <li>Summary Report</li>
                        <li>Function Helper</li>
                        <li>HTTP Request Sampler</li>
                      </ul>
                      <li>Record a script for web performance testing</li>
                      <li>HTTP Request testing</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <a
                class="face-button"
                href="/files/Brochure.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="face-primary">
                  <span class="icon fa fa-download"></span>
                  Course Details
                </div>

                <div class="face-secondary">
                  <span class="icon fa fa-hdd-o"></span>
                  Size: 4.80 MB
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Accordion end */}

      {/* Price start */}

      <section id="price" className="pricing">
        <div className="container">
          <div className="section-title">
            <h2>Course Fee</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-md-4">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    Price
                  </h5>
                  {/* <h6 className="card-price text-center">
                    $3500
                    <span className="period">One Time</span>
                  </h6> */}
                  <hr />

                  <h5 className="card-title text-muted text-uppercase text-center">
                    <a style={{ textDecoration: "none" }} href="/contact-us">
                      Call Us
                    </a>{" "}
                    For Course Fees
                  </h5>
                  {/* <h6 className="card-price text-center">
                    $3000
                    <span className="period">One Time</span>
                  </h6> */}
                  <hr />
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Lectures & Exams
                      {/* <strong>Lectures & Exams</strong> */}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      MCQ Quizes
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Practical
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Question Answering
                    </li>
                  </ul>
                  <div className="d-grid">
                    <a
                      href="/registration"
                      className="btn btn-primary text-uppercase"
                    >
                      Enroll
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Price end */}

      {/* table start */}
      <section>
        <div class="container">
          <div className="section-title">
            <h2>Current Course Schedule</h2>
          </div>
          <p>
            <strong>Weekend Classes: </strong> {courseDetails?.weekend_classes} |
            New York Time
            <br></br>
            <strong>Student Support Session: </strong> {courseDetails?.student_support_session} |
            New York Time
            <br></br>
          </p>
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Time</th>
                <th scope="col">Date (Weekend)</th>
                {/* <th scope="col">Date (Weekday)</th> */}
                <th scope="col">Modules</th>
              </tr>
            </thead>
            <tbody>
              {
                sortWeeks(courseWeeks).map((week, index) => (
                  <tr key={week?.week}>
                    <th scope="row"></th>
                    <td>{week.week} ({courseDetails.class_duration})</td>
                    <td>
                      {week.date_01}<br></br>
                      {week.date_02}
                    </td>
                    <td>{week.module}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </section>

      {/* table end */}

      {/* tools start */}

      <section>
        <div className="container">
          <div className="section-title">
            <h2>Tools</h2>
          </div>
          <div className="row my-4">
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-jira mt-3"></i>
                  </h2>
                  <h5 className="card-title">Atlassian JIRA</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-database mt-3"></i>
                  </h2>
                  <h5 className="card-title">POSTGRESQL</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-cog mt-3"></i>
                  </h2>
                  <h5 className="card-title">MAVEN</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row  my-4">
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-file-excel mt-3"></i>
                  </h2>
                  <h5 className="card-title">Excel</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-node mt-3"></i>
                  </h2>
                  <h5 className="card-title">NODE JS</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-github"></i>
                  </h2>
                  <h5 className="card-title">GITHUB</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row  my-4">
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-code-branch"></i>
                  </h2>
                  <h5 className="card-title">INTELLIJ IDE</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-java"></i>
                  </h2>
                  <h5 className="card-title">JAVA</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-grip-lines"></i>
                  </h2>
                  <h5 className="card-title">REST ASSURED</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row  my-4">
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-java"></i>
                  </h2>
                  <h5 className="card-title">TESTNG</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-server"></i>
                  </h2>
                  <h5 className="card-title">JSON SERVER</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-envelope-open"></i>
                  </h2>
                  <h5 className="card-title">POSTMAN</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row  my-4">
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-tasks"></i>
                  </h2>
                  <h5 className="card-title">CUCUMBER</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-genderless"></i>
                  </h2>
                  <h5 className="card-title">SMARTBEAR ZEPHYR</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fas fa-comment-alt"></i>
                  </h2>
                  <h5 className="card-title">SELENIUM</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* tools end */}
      <Footer />
    </>
  );
}

export default SoftwareTestingCourse;
