// import Topbar from "./Topbar";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Tabs, Tab } from "react-bootstrap";
// import "aos/dist/aos.css";
// import AOS from "aos";
// import jsonData from './local_api/accessibility.json';
// import "aos/dist/aos.css";
// import "./BasicComputingCourse.css";
// import ShajibKhadem from "./assets/Shajib-khadem.jpg";
// import RifatHalim from "./assets/Rifat-Halim.JPG";
// import FaridUddin from "./assets/Farid-Uddin.jpeg";
// import nmap from "./assets/nmap_icon.png";
// import NessusProfessional from "./assets/NessusProfessional.png";
// import Burpsuite from "./assets/Burpsuite.png";
// import Metasploit from "./assets/Metasploit.jpg";


// function CyberSecurity() {
//   AOS.init();

//   // data fetching function for localapi
//   function fetchDataById(id) {
//     const result = jsonData.find(item => item.id === id);
//     return result ? result : null;
//   }
//   const courseDetails? = fetchDataById(3);

//   const formatModule = (module) => {
//     return module.split('\n').map((line, index) => (
//       <span key={index}>
//         {line}
//         <br />
//       </span>
//     ));
//   };

//   return (
//     <>
//       <Navbar />

//       {/* Slider start */}

//       <section className="bgimage">
//         <div className="container">
//           <div className="row par">
//             <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
//               <h1 className="heading1text">
//                 {courseDetails?.course_name}
//               </h1>
//               <h3 className="heading3text">
//                 Comprehensive CompTIA Security+ Certification (SY0-601)
//               </h3>
//               <br />
//               <p>
//                 {courseDetails?.course_description}
//               </p>
//               <h3 className="mb-3">Upcoming Batch Details</h3>
//               <p>
//                 Regular Class: {courseDetails?.start_date}
//                 <br />
//                 Trial Class: {courseDetails?.trial_date}
//               </p>
//               <p>
//                 <a href="#price" className="btn btn-primary btn-large">
//                   Course Fee »
//                 </a>
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Slider end */}

//       {/* course info start */}
//       <section>
//         <div id="parallax-world-of-ugg">
//           <div className="container">
//             <div className="section-title text-center">
//               <h2>Course Overview</h2>
//             </div>
//             <div className="block">
//               <p>
//                 Cybersecurity is one of the most demandable job fields in this
//                 technology based modern world which is safeguarding of protected
//                 information and critical data online. Small to larger
//                 organizations implement cybersecurity measures to defend
//                 sensitive data from both internal and external threats and to
//                 best prepare for a cyber-attack.<br></br>
//                 <br></br>
//                 The CompTIA Security+ certification validates that you have the
//                 core skills necessary for a career in the cybersecurity arena.
//                 It is a vendor-neutral security certification that is a good
//                 place to start. It teaches basic security concepts and is seen
//                 by many as the first port of call on the way to studying more
//                 advanced certifications. Because it is aimed at entry-level
//                 security professionals, it offers generalized information that
//                 will help candidates build a fundamental understanding of
//                 information security.
//                 <br></br>
//                 <br></br>
//                 This comprehensive course is equivalent to two years of hands-on
//                 experience working in a security/systems administrator or
//                 specialist role. Candidates who will successfully complete this
//                 course will have the ability to pass the CompTIA Security+
//                 certification exam.
//               </p>
//               {/* <p className="line-break margin-top-10" />
//               <p className="margin-top-10">
//                 This course will provide complete guidance on how to provide
//                 technical assistance and support related to IT systems, hardware
//                 & software solutions as well as to manage customer expectations
//                 with professionalism in a timely manner.
//               </p> */}
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* course info end */}

//       {/* Tab start */}

//       <div className="container">
//         <div className="row" data-aos="fade-up">
//           <div className="col-12">
//             <section className="payment">
//               <Tabs
//                 defaultActiveKey="highlight"
//                 id="uncontrolled-tab-example"
//                 className="tab-menu"
//               >
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="highlight"
//                   title="Course Highlight"
//                 >
//                   <ul>
//                     <li>
//                       Blended learning delivery with 108 hours of instructor-led
//                       hybrid (Online only) training
//                     </li>
//                     <li>Certified & experienced trainers</li>
//                     <li>
//                       Hands on case studies where the candidates will use given
//                       tools and techniques
//                     </li>
//                     <li>
//                       Quiz to prepare for the CompTIA Security+ certificate
//                       examination
//                     </li>
//                     <li>
//                       CompTIA Security+ certificate examination fees
//                       reimbursement for successful candidates (a US$400 value)
//                     </li>
//                     <li>
//                       Internship opportunity after successful course completion
//                     </li>
//                     <li>Job support guidance</li>
//                     {/* <li>Start Date: January 24, 2022 (Weekdays Batch)</li> */}
//                     {/* <li>
//                       Duration: 14 Weeks.
//                       <ul>
//                         <li>Regular Class: 112 Hours</li>
//                         <li>Student Support Session: 14 Hours</li>
//                       </ul>
//                     </li> */}
//                   </ul>
//                 </Tab>

//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="home"
//                   title="Course Information"
//                 >
//                   <ul>
//                     <li>
//                       Classes: Saturday & Sunday, 10 am – 2 pm | New York Time
//                       (EST)
//                     </li>
//                     <li>
//                       Review Class: Tuesday, 6.30 pm – 7.30 pm | New York Time
//                       (EST)
//                     </li>
//                     <li>Duration: 12 Weeks, 108 Hours</li>
//                     <li>
//                       Start Date and Course Fees:<br></br> Please contact us to
//                       know the fees and updated batch schedule:
//                       <a href="https://qateksolutions.com/contact-us">
//                         qateksolutions.com/contact-us
//                       </a>
//                     </li>
//                     <li>
//                       Textbook:<br></br>CompTIA Security+ SY0-601 Certification
//                       Guide by Ian Neil. ISBN-13: 978-1800564244 | ISBN-10:
//                       1800564244
//                     </li>
//                     <li>
//                       Target Audience
//                       <ul>
//                         <li>System Administrators</li>
//                         <li>Security Engineers and consultants</li>
//                         <li>Network administrators</li>
//                         <li>IT Auditors/Penetration Testers</li>
//                       </ul>
//                     </li>
//                     <li>
//                       Certificate exam (SY0-601) Information
//                       <ul>
//                         <li>Duration: 90 minutes</li>
//                         <li>Number of questions: 90 questions per exam</li>
//                         <li>
//                           Question format: Multiple choice and performance-based
//                         </li>
//                         <li>Pass score: 750 (on a scale of 100-900)</li>
//                       </ul>
//                     </li>
//                   </ul>
//                 </Tab>
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="profile"
//                   title="Who Can Join"
//                 >
//                   <ul>
//                     <li>The following are pre-requisites for this course:</li>
//                     <ul>
//                       <li>
//                         Bachelor’s degree in any subject (Preferred but not
//                         required)
//                       </li>
//                       <li>Basic understanding of computing</li>
//                       <li>Anyone looking for a career change</li>
//                       <li>Proficiency in English will be a huge plus </li>
//                       <li>
//                         Must have a personal computer (desktop/laptop) with
//                         Microsoft Windows 2010/2011 or Apple macOS with good
//                         internet connection. The computer/laptop must have
//                         microphone, webcam, and Zoom software installed (we will
//                         help)
//                       </li>
//                     </ul>
//                   </ul>
//                 </Tab>
//                 {/* <Tab
//                   className="tab-item tabcourse"
//                   eventKey="prerequisites"
//                   title="Prerequisites"
//                 >
//                   <ul>
//                     <li>
//                       A motivated person with a Bachelor degree in any
//                       discipline who is seeking an IT professional job.
//                     </li>
//                   </ul>
//                 </Tab> */}
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="contact"
//                   title="Learning Outcome"
//                 >
//                   <ul>
//                     <li>
//                       At the completion of the course, the candidates will be
//                       able to do the following:
//                     </li>
//                     <ul>
//                       <li>
//                         Assess the security posture of enterprise systems and
//                         information.{" "}
//                       </li>
//                       <li>
//                         Recommend and implement appropriate security solutions
//                         for different environments, including cloud, mobile, and
//                         IoT.{" "}
//                       </li>
//                       <li>
//                         Monitor security posture of enterprise systems and
//                         information{" "}
//                       </li>
//                       <li>
//                         Operate organizational systems with an awareness of
//                         applicable laws and policies, including principles of
//                         governance, risk, and compliance{" "}
//                       </li>
//                       <li>
//                         Identify, analyze, and respond to security events and
//                         incidents
//                       </li>
//                       <li>
//                         Prepare to sit for CompTIA Security+ certification
//                         examination: SY0-601
//                       </li>
//                     </ul>
//                   </ul>
//                 </Tab>
//                 <Tab
//                   className="tab-item tabcourse"
//                   eventKey="check"
//                   title="Instruction Method"
//                 >
//                   <ul>
//                     <li>
//                       Live Instructor-led hybrid (Online only) training
//                       conducted in English{" "}
//                     </li>
//                   </ul>
//                 </Tab>
//               </Tabs>
//             </section>
//           </div>
//         </div>
//       </div>

//       {/* Tab end */}

//       {/* Accordion start */}

//       <section>
//         <div class="accordion container" id="accordionExample">
//           <div className="section-title text-center">
//             <h2>Course Outline</h2>
//           </div>
//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingOne">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseOne"
//                 aria-expanded="true"
//                 aria-controls="collapseOne"
//               >
//                 Week 1: Basic Computing: Computer Fundamentals, Operating
//                 Systems (OS)
//               </button>
//             </h2>
//             <div
//               id="collapseOne"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingOne"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Computer architecture including hardware concepts and
//                     software introduction
//                   </li>
//                   <li>
//                     Internet, Email, Calendar Management, Remote Desktop, and
//                     VPN
//                   </li>
//                   <li>Video conferencing tools: Zoom, Meet, Teams</li>
//                   <li>Office communication tools: Slack</li>
//                   <li>Learning Management System (LMS)</li>
//                   <li>
//                     Operating Systems (OS): Microsoft Windows OS, Apple macOS,
//                     Linux OS (Ubuntu)
//                   </li>
//                   <li>
//                     Managing files, installing and uninstalling software in
//                     different OS
//                   </li>
//                   <li>Graphical User Interface (GUI) intro in different OS </li>
//                   <li>Command Line Interface (CLI) examples in different OS</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingTwo">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseTwo"
//                 aria-expanded="false"
//                 aria-controls="collapseTwo"
//               >
//                 Week 2: Basic Computing: Google Suite/Microsoft Office, Customer
//                 Service, Cybersecurity Basics
//               </button>
//             </h2>
//             <div
//               id="collapseTwo"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingTwo"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>Google Docs/MS Word</li>
//                   <li>Google Sheets/MS Excel</li>
//                   <li>Google Slides/MS PowerPoint</li>
//                   <li>
//                     Customer Service Principles, Problem Solving, Effective
//                     Communication
//                   </li>
//                   <li>
//                     Cybersecurity concepts, security threats with examples
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingThree">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseThree"
//                 aria-expanded="false"
//                 aria-controls="collapseThree"
//               >
//                 Week 3: Basic Networking
//               </button>
//             </h2>
//             <div
//               id="collapseThree"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingThree"
//               data-bs-parent="#accordionExample"
//             >
//               <div className="accordion-body text-white">
//                 <ul>
//                   <li>Fundamental of Network</li>
//                   <li>Local Area Network (LAN) and Wide Area Network (WAN)</li>
//                   <li>Network layers</li>
//                   <li>Open System Interconnection (OSI) model</li>
//                   <li>Understanding of the OSI Layers</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingFour">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseFour"
//                 aria-expanded="false"
//                 aria-controls="collapseFour"
//               >
//                 Week 4: Basic Networking
//               </button>
//             </h2>
//             <div
//               id="collapseFour"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingFour"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>What is a port and the common network ports?</li>
//                   <li>Network protocol and how it works.</li>
//                   <li>What is a firewall</li>
//                   <li>Basics of routers and switches</li>
//                   <li>Review and discussion on Week 1-4</li>
//                   <li>
//                     <i>
//                       Exam: Basic Computing & Networking and analysis of answers
//                       (Week 1-4)
//                     </i>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingFive">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseFive"
//                 aria-expanded="false"
//                 aria-controls="collapseFive"
//               >
//                 Week 5: Threats, Attacks, and Vulnerabilities
//               </button>
//             </h2>
//             <div
//               id="collapseFive"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingFive"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Compare and contrast different types of social engineering
//                     techniques.
//                   </li>
//                   <li>
//                     Given a scenario, analyze potential indicators to determine
//                     the type of attacks.
//                   </li>
//                   <li>
//                     Explain different threat actors, vectors, and intelligence
//                     sources.
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSix">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseSix"
//                 aria-expanded="false"
//                 aria-controls="collapseSix"
//               >
//                 Week 6: Threats, Attacks, and Vulnerabilities
//               </button>
//             </h2>
//             <div
//               id="collapseSix"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSix"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Explain the security concerns associated with various types
//                     of vulnerabilities
//                   </li>
//                   <li>
//                     Summarize the techniques used in security assessments{" "}
//                   </li>
//                   <li>Explain the techniques used in penetration testing</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseSeven"
//                 aria-expanded="false"
//                 aria-controls="collapseSeven"
//               >
//                 Week 7: Architecture and Design
//               </button>
//             </h2>
//             <div
//               id="collapseSeven"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Explain the importance of security concepts in an enterprise
//                     environment{" "}
//                   </li>
//                   <li>
//                     Summarize virtualization and cloud computing concepts.{" "}
//                   </li>
//                   <li>
//                     Summarize secure application development, deployment, and
//                     automation concepts.
//                   </li>
//                   <li>
//                     Summarize authentication and authorization design concepts{" "}
//                   </li>
//                   <li>Given a scenario, implement cybersecurity resilience </li>
//                   <li>
//                     Explain the security implications of embedded and
//                     specialized systems.{" "}
//                   </li>
//                   <li>
//                     <i>
//                       Exam: Threats, Attacks, and Vulnerabilities and analysis
//                       of answers (Week 5-6)
//                     </i>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseEight"
//                 aria-expanded="false"
//                 aria-controls="collapseEight"
//               >
//                 Week 8: Architecture and Design | Implementation
//               </button>
//             </h2>
//             <div
//               id="collapseEight"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Explain the importance of physical security controls.{" "}
//                   </li>
//                   <li>Explain the basics of cryptographic concepts </li>
//                   <li>
//                     Given a scenario, implement secure protocols, and host or
//                     application security solutions,{" "}
//                   </li>
//                   <li>Given a scenario, implement secure network designs </li>
//                   <li>
//                     Given a scenario, install and configure wireless security
//                     settings.
//                   </li>
//                   <li>
//                     <i>
//                       Exam on Architecture and Design and analysis of answers
//                       (Week 7-8)
//                     </i>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseNine"
//                 aria-expanded="false"
//                 aria-controls="collapseNine"
//               >
//                 Week 9: Implementation
//               </button>
//             </h2>
//             <div
//               id="collapseNine"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>Given a scenario, implement secure mobile solutions. </li>
//                   <li>
//                     Given a scenario, apply cybersecurity solutions to the
//                     cloud.{" "}
//                   </li>
//                   <li>
//                     Given a scenario, implement identity and account management
//                     controls{" "}
//                   </li>
//                   <li>
//                     Given a scenario, implement authentication and authorization
//                     solutions{" "}
//                   </li>
//                   <li>Given a scenario, implement public key infrastructure</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseTen"
//                 aria-expanded="false"
//                 aria-controls="collapseTen"
//               >
//                 Week 10: Operations and Incident Response
//               </button>
//             </h2>
//             <div
//               id="collapseTen"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>
//                     Given a scenario, use the appropriate tool to assess
//                     organizational security.{" "}
//                   </li>
//                   <li>
//                     Summarize the importance of policies, processes, and
//                     procedures for incident response.{" "}
//                   </li>
//                   <li>
//                     Given an incident, utilize appropriate data sources to
//                     support an investigation.{" "}
//                   </li>
//                   <li>
//                     Given an incident, apply mitigation techniques or controls
//                     to secure an environment.{" "}
//                   </li>
//                   <li>Explain the key aspects of digital forensics. </li>
//                   <li>
//                     <i>
//                       Exam on Implementation and analysis of answers (Week 9)
//                     </i>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseEleven"
//                 aria-expanded="false"
//                 aria-controls="collapseEleven"
//               >
//                 Week 11: Governance, Risk and Compliance
//               </button>
//             </h2>
//             <div
//               id="collapseEleven"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <li>Compare and contrast various types of controls. </li>
//                   <li>
//                     Explain the importance of applicable regulations, standards,
//                     or frameworks that impact organizational security posture.{" "}
//                   </li>
//                   <li>
//                     Explain the importance of policies to organizational
//                     security.{" "}
//                   </li>
//                   <li>Summarize risk management processes and concepts </li>
//                   <li>
//                     Explain privacy and sensitive data concepts in relation to
//                     security.{" "}
//                   </li>
//                   <li>
//                     <i>
//                       Exam on Operations and Incident Response and analysis of
//                       answers (Week 10-11)
//                     </i>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div class="accordion-item">
//             <h2 class="accordion-header" id="headingSeven">
//               <button
//                 class="accordion-button collapsed"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#collapseTwelve"
//                 aria-expanded="false"
//                 aria-controls="collapseTwelve"
//               >
//                 Week 12: Exam and Job Guidance
//               </button>
//             </h2>
//             <div
//               id="collapseTwelve"
//               class="accordion-collapse collapse accord"
//               aria-labelledby="headingSeven"
//               data-bs-parent="#accordionExample"
//             >
//               <div class="accordion-body text-white">
//                 <ul>
//                   <i>
//                     <li>Final Exam</li>
//                   </i>
//                   <li>Final exam analysis of answers</li>
//                   <li>Job support guidance</li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col">
//               <a
//                 class="face-button"
//                 href="/files/Brochure.pdf"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <div class="face-primary">
//                   <span class="icon fa fa-download"></span>
//                   Course Details
//                 </div>

//                 <div class="face-secondary">
//                   <span class="icon fa fa-hdd-o"></span>
//                   Size: 4.80 MB
//                 </div>
//               </a>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Accordion end */}

//       {/* Instructor start */}

//       <section id="features" className="features" data-aos="fade-up">
//         <div className="container">
//           <div className="section-title">
//             <h2>Course Instructors</h2>
//           </div>

//           <div className="row content">
//             {/* <div
//               className="col-md-5"
//               //   data-aos="fade-right"
//               //   data-aos-delay="100"
//             >
//               <img src={RifatHalim} className="img-fluid about-image" alt="" />
//             </div> */}
//             <div
//               className="col-md-12 pt-4 text-center"
//               data-aos="fade-left"
//               data-aos-delay="100"
//             >
//               <h1 className="name">Mesbah Islam</h1>
//               {/* <h3 className="position">President & Instructor</h3> */}
//               <p className="fst-italic">
//                 The lead instructor for CompTIA Security+ course is a veteran
//                 working in private and mostly in public sector as a software
//                 developer, Test Architect and Manager, and Cybersecurity
//                 professional. Currently he is performing the role of Information
//                 Security Manager in a highly visible federal agency. Our
//                 instructors have profound knowledge on market trend, demands,
//                 usability and hiring practices within public and private sector.
//               </p>
//             </div>
//           </div>

//           {/* <div className="row content">
//             <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
//               <img src={FaridUddin} className="img-fluid about-image" alt="" />
//             </div>
//             <div
//               className="col-md-7 pt-5 order-2 order-md-1"
//               data-aos="fade-right"
//             >
//               <h1 className="name">Mohammad Uddin</h1>
//               <h3 className="position">Vice President & Instructor​</h3>
//               <p className="fst-italic">
//                 Mr. Uddin is the lead instructor in QA Tek Solutions Inc. He is
//                 a SQA Engineer with Lowes Company, one of the largest retail
//                 companies in the US. He has been in IT Industries for quite a
//                 bit of time. He has been an expert SQA Engineer and working in
//                 different domains. His style of teaching software testing is
//                 simple and deep.
//               </p>
//             </div>
//           </div> */}

//           {/* <div className="row content">
//             <div className="col-md-5" data-aos="fade-right">
//               <img
//                 src={ShajibKhadem}
//                 className="img-fluid about-image"
//                 alt=""
//               />
//             </div>

//             <div className="col-md-7 pt-5" data-aos="fade-left">
//               <h1 className="name">Shajib Khadem</h1>
//               <h3 className="position">Technical Advisor</h3>
//               <p className="fst-italic">
//                 Mr. Shajib Khadem is a Lead Instructor in QA Tek Solutions Inc.
//                 He is currently working in Verizon as a Lead Consultant. He has
//                 vast experience in telecommunications, automation, IT solutions,
//                 customer service & assurance, process improvements, change
//                 management, professional training development from the USA,
//                 Australia, and Netherland.
//               </p>
//             </div>
//           </div> */}
//         </div>
//       </section>

//       {/* Instructor end */}

//       {/* Price start */}

//       <section id="price" className="pricing">
//         <div className="container">
//           <div className="section-title">
//             <h2>Course Fee</h2>
//           </div>
//           <div className="row">
//             <div className="col-lg-4 offset-md-4">
//               <div className="card mb-5 mb-lg-0">
//                 <div className="card-body">
//                   <h5 className="card-title text-muted text-uppercase text-center">
//                     Price
//                   </h5>
//                   {/* <h6 className="card-price text-center">
//                     $3500
//                     <span className="period">One Time</span>
//                   </h6> */}
//                   <hr />

//                   <h5 className="card-title text-muted text-uppercase text-center">
//                     <a style={{ textDecoration: "none" }} href="/contact-us">
//                       Call Us
//                     </a>{" "}
//                     For Course Fees
//                   </h5>
//                   {/* <h6 className="card-price text-center">
//                     $3000
//                     <span className="period">One Time</span>
//                   </h6> */}
//                   <hr />
//                   <ul className="fa-ul">
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       Lectures & Exams
//                       {/* <strong>Lectures & Exams</strong> */}
//                     </li>
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       MCQ Quizes
//                     </li>
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       Practical
//                     </li>
//                     <li>
//                       <span className="fa-li">
//                         <i className="fas fa-check" />
//                       </span>
//                       Question Answering
//                     </li>
//                   </ul>
//                   <div className="d-grid">
//                     <a
//                       href="/registration"
//                       className="btn btn-primary text-uppercase"
//                     >
//                       Enroll
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Price end */}

//       {/* table start */}
//       {/* <section>
//         <div class="container">
//           <div className="section-title">
//             <h2>Current Course Schedule</h2>
//           </div>
//           <p>
//             <strong>Classes: </strong>Saturday & Sunday, 10 am – 2 pm | New York
//             Time (EST)
//             <br></br>
//             <strong>Weekdays Classes: </strong>Monday, Wednesday & Friday, 6.30
//             pm – 9.30 pm | New York Time<br></br>
//             <strong>Review Class: </strong>Tuesday, 6.30 pm – 7.30 pm | New York
//             Time (EST)
//             <br></br>
//           </p>
//           <table class="table text-center">
//             <thead>
//               <tr>
//                 <th scope="col"></th>
//                 <th scope="col">Time</th>
//                 <th scope="col">Date (Weekend)</th>
//                 <th scope="col">Modules</th>
//               </tr>
//             </thead>
//             <tbody>
//               {
//                 courseDetails?.weeks.map((week) => (
//                   <tr key={week.week}>
//                     <th scope="row"></th>
//                     <td>{week.week} ({courseDetails?.class_duraton})</td>
//                     <td>
//                       {week.date_01}<br></br>
//                       {week.date_02}
//                     </td>
//                     <td>
//                       {formatModule(week.module)}
//                     </td>
//                   </tr>
//                 ))
//               }
//             </tbody>
//           </table>
//         </div>
//       </section> */}

//       {/* table end */}

//       {/* tools start */}

//       <section>
//         <div className="container">
//           <div className="section-title">
//             <h2>Tools</h2>
//           </div>
//           <div className="row my-4">
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <i class="fab fa-windows mt-3"></i>
//                   </h2>
//                   <h5 className="card-title">Recon-no</h5>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <img width={45} src={nmap} alt="" />
//                   </h2>
//                   <h5 className="card-title">Nmap</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block">
//                   <h2>
//                     <img width={100} src={NessusProfessional} alt="" />
//                   </h2>
//                   <h5 className="card-title">Nessus</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block mt-5">
//                   <h2>
//                     <img width={35} src={Burpsuite} alt="" />
//                   </h2>
//                   <h5 className="card-title">Burpsuite</h5>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-4">
//               <div className=" text-center">
//                 <div className="card-block mt-5">
//                   <h2>
//                     <img width={35} src={Metasploit} alt="" />
//                   </h2>
//                   <h5 className="card-title">Metasploit</h5>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* tools end */}
//       <Footer />
//     </>
//   );
// }

// export default CyberSecurity;




import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Tabs, Tab } from "react-bootstrap";
import "aos/dist/aos.css";
import AOS from "aos";
import jsonData from './local_api/accessibility.json';
import "aos/dist/aos.css";
import "./BasicComputingCourse.css";
import ShajibKhadem from "./assets/Shajib-khadem.jpg";
import RifatHalim from "./assets/Rifat-Halim.JPG";
import FaridUddin from "./assets/Farid-Uddin.jpeg";
import nmap from "./assets/nmap_icon.png";
import NessusProfessional from "./assets/NessusProfessional.png";
import Burpsuite from "./assets/Burpsuite.png";
import Metasploit from "./assets/Metasploit.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { getCourseWeeks, getcourses } from "./local_api/ApiService";


function CyberSecurity() {
  AOS.init();

  const formatModule = (module) => {
    return module.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  // data fetching function for localapi
  const id = 6;

  const [courseDetails, setCourseDetails] = useState(null);
  const [courseWeeks, setCourseWeeks] = useState([]);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const courses = await getcourses();
        const course = courses.find((course) => course.id === parseInt(id));

        if (course) {
          setCourseDetails(course);

          // Fetch weeks data for the course from ApiService
          const weeks = await getCourseWeeks(id);
          setCourseWeeks(weeks);
        } else {
          setError("Course not found");
        }
      } catch (error) {
        setError("Error fetching data");
      }
    };

    fetchData();
  }, []);

  console.log('courses', courseDetails);
  console.log('weeks', courseWeeks);

  const sortWeeks = (weeks) => {
    return weeks.slice().sort((a, b) => {
      const weekA = a.week.toLowerCase();
      const weekB = b.week.toLowerCase();
      return weekA.localeCompare(weekB, undefined, { numeric: true, sensitivity: 'base' });
    });
  };

  return (
    <>
      <Navbar />

      {/* Slider start */}

      <section className="bgimage">
        <div className="container">
          <div className="row par">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center para">
              <h1 className="heading1text">
                {courseDetails?.course_name}
              </h1>
              {/* <h3 className="heading3text">
                Comprehensive CompTIA Security+ Certification (SY0-601)
              </h3> */}
              <br />
              <p>
                {courseDetails?.course_description}
              </p>
              <h3 className="mb-3">Upcoming Batch Details</h3>
              <p>
                Regular Class: {courseDetails?.start_date}
                <br />
                Trial Class: {courseDetails?.trial_date}
              </p>
              <p>
                <a href="#price" className="btn btn-primary btn-large">
                  Course Fee »
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Slider end */}

      {/* course info start */}
      <section>
        <div id="parallax-world-of-ugg">
          <div className="container">
            <div className="section-title text-center">
              <h2>Course Overview</h2>
            </div>
            <div className="block">
              <p>
                Cybersecurity is one of the most demandable job fields in this
                technology based modern world which is safeguarding of protected
                information and critical data online. Small to larger
                organizations implement cybersecurity measures to defend
                sensitive data from both internal and external threats and to
                best prepare for a cyber-attack.<br></br>
                <br></br>
                The CompTIA Security+ certification validates that you have the
                core skills necessary for a career in the cybersecurity arena.
                It is a vendor-neutral security certification that is a good
                place to start. It teaches basic security concepts and is seen
                by many as the first port of call on the way to studying more
                advanced certifications. Because it is aimed at entry-level
                security professionals, it offers generalized information that
                will help candidates build a fundamental understanding of
                information security.
                <br></br>
                <br></br>
                This comprehensive course is equivalent to two years of hands-on
                experience working in a security/systems administrator or
                specialist role. Candidates who will successfully complete this
                course will have the ability to pass the CompTIA Security+
                certification exam.
              </p>
              {/* <p className="line-break margin-top-10" />
              <p className="margin-top-10">
                This course will provide complete guidance on how to provide
                technical assistance and support related to IT systems, hardware
                & software solutions as well as to manage customer expectations
                with professionalism in a timely manner.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* course info end */}

      {/* Tab start */}

      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="col-12">
            <section className="payment">
              <Tabs
                defaultActiveKey="highlight"
                id="uncontrolled-tab-example"
                className="tab-menu"
              >
                <Tab
                  className="tab-item tabcourse"
                  eventKey="highlight"
                  title="Course Highlight"
                >
                  <ul>
                    <li>
                      Blended learning delivery with 108 hours of instructor-led
                      hybrid (Online only) training
                    </li>
                    <li>Certified & experienced trainers</li>
                    <li>
                      Hands on case studies where the candidates will use given
                      tools and techniques
                    </li>
                    <li>
                      Quiz to prepare for the CompTIA Security+ certificate
                      examination
                    </li>
                    <li>
                      CompTIA Security+ certificate examination fees
                      reimbursement for successful candidates (a US$400 value)
                    </li>
                    <li>
                      Internship opportunity after successful course completion
                    </li>
                    <li>Job support guidance</li>
                    {/* <li>Start Date: January 24, 2022 (Weekdays Batch)</li> */}
                    {/* <li>
                      Duration: 14 Weeks.
                      <ul>
                        <li>Regular Class: 112 Hours</li>
                        <li>Student Support Session: 14 Hours</li>
                      </ul>
                    </li> */}
                  </ul>
                </Tab>

                <Tab
                  className="tab-item tabcourse"
                  eventKey="home"
                  title="Course Information"
                >
                  <ul>
                    <li>
                      Classes: {courseDetails?.classes} | New York Time
                      (EST)
                    </li>
                    <li>
                      Review Class: {courseDetails?.review_class} | New York Time
                      (EST)
                    </li>
                    <li>Duration: {courseDetails?.course_duration}, {courseDetails?.total_class_duration}</li>
                    <li>
                      Start Date and Course Fees:<br></br> Please contact us to
                      know the fees and updated batch schedule:
                      <a href="https://qateksolutions.com/contact-us">
                        qateksolutions.com/contact-us
                      </a>
                    </li>
                    <li>
                      Textbook:<br></br>CompTIA Security+ SY0-601 Certification
                      Guide by Ian Neil. ISBN-13: 978-1800564244 | ISBN-10:
                      1800564244
                    </li>
                    <li>
                      Target Audience
                      <ul>
                        <li>System Administrators</li>
                        <li>Security Engineers and consultants</li>
                        <li>Network administrators</li>
                        <li>IT Auditors/Penetration Testers</li>
                      </ul>
                    </li>
                    <li>
                      Certificate exam (SY0-601) Information
                      <ul>
                        <li>Duration: 90 minutes</li>
                        <li>Number of questions: 90 questions per exam</li>
                        <li>
                          Question format: Multiple choice and performance-based
                        </li>
                        <li>Pass score: 750 (on a scale of 100-900)</li>
                      </ul>
                    </li>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="profile"
                  title="Who Can Join"
                >
                  <ul>
                    <li>The following are pre-requisites for this course:</li>
                    <ul>
                      <li>
                        Bachelor’s degree in any subject (Preferred but not
                        required)
                      </li>
                      <li>Basic understanding of computing</li>
                      <li>Anyone looking for a career change</li>
                      <li>Proficiency in English will be a huge plus </li>
                      <li>
                        Must have a personal computer (desktop/laptop) with
                        Microsoft Windows 2010/2011 or Apple macOS with good
                        internet connection. The computer/laptop must have
                        microphone, webcam, and Zoom software installed (we will
                        help)
                      </li>
                    </ul>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="contact"
                  title="Learning Outcome"
                >
                  <ul>
                    <li>
                      At the completion of the course, the candidates will be
                      able to do the following:
                    </li>
                    <ul>
                      <li>
                        Assess the security posture of enterprise systems and
                        information.{" "}
                      </li>
                      <li>
                        Recommend and implement appropriate security solutions
                        for different environments, including cloud, mobile, and
                        IoT.{" "}
                      </li>
                      <li>
                        Monitor security posture of enterprise systems and
                        information{" "}
                      </li>
                      <li>
                        Operate organizational systems with an awareness of
                        applicable laws and policies, including principles of
                        governance, risk, and compliance{" "}
                      </li>
                      <li>
                        Identify, analyze, and respond to security events and
                        incidents
                      </li>
                      <li>
                        Prepare to sit for CompTIA Security+ certification
                        examination: SY0-601
                      </li>
                    </ul>
                  </ul>
                </Tab>
                <Tab
                  className="tab-item tabcourse"
                  eventKey="check"
                  title="Instruction Method"
                >
                  <ul>
                    <li>
                      Live Instructor-led hybrid (Online only) training
                      conducted in English{" "}
                    </li>
                  </ul>
                </Tab>
              </Tabs>
            </section>
          </div>
        </div>
      </div>

      {/* Tab end */}

      {/* Accordion start */}

      <section>
        <div class="accordion container" id="accordionExample">
          <div className="section-title text-center">
            <h2>Course Outline</h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Week 1: Basic Computing: Computer Fundamentals, Operating
                Systems (OS)
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Computer architecture including hardware concepts and
                    software introduction
                  </li>
                  <li>
                    Internet, Email, Calendar Management, Remote Desktop, and
                    VPN
                  </li>
                  <li>Video conferencing tools: Zoom, Meet, Teams</li>
                  <li>Office communication tools: Slack</li>
                  <li>Learning Management System (LMS)</li>
                  <li>
                    Operating Systems (OS): Microsoft Windows OS, Apple macOS,
                    Linux OS (Ubuntu)
                  </li>
                  <li>
                    Managing files, installing and uninstalling software in
                    different OS
                  </li>
                  <li>Graphical User Interface (GUI) intro in different OS </li>
                  <li>Command Line Interface (CLI) examples in different OS</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Week 2: Basic Computing: Google Suite/Microsoft Office, Customer
                Service, Cybersecurity Basics
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Google Docs/MS Word</li>
                  <li>Google Sheets/MS Excel</li>
                  <li>Google Slides/MS PowerPoint</li>
                  <li>
                    Customer Service Principles, Problem Solving, Effective
                    Communication
                  </li>
                  <li>
                    Cybersecurity concepts, security threats with examples
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Week 3: Basic Networking
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <ul>
                  <li>Fundamental of Network</li>
                  <li>Local Area Network (LAN) and Wide Area Network (WAN)</li>
                  <li>Network layers</li>
                  <li>Open System Interconnection (OSI) model</li>
                  <li>Understanding of the OSI Layers</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Week 4: Basic Networking
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>What is a port and the common network ports?</li>
                  <li>Network protocol and how it works.</li>
                  <li>What is a firewall</li>
                  <li>Basics of routers and switches</li>
                  <li>Review and discussion on Week 1-4</li>
                  <li>
                    <i>
                      Exam: Basic Computing & Networking and analysis of answers
                      (Week 1-4)
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Week 5: Threats, Attacks, and Vulnerabilities
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Compare and contrast different types of social engineering
                    techniques.
                  </li>
                  <li>
                    Given a scenario, analyze potential indicators to determine
                    the type of attacks.
                  </li>
                  <li>
                    Explain different threat actors, vectors, and intelligence
                    sources.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Week 6: Threats, Attacks, and Vulnerabilities
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Explain the security concerns associated with various types
                    of vulnerabilities
                  </li>
                  <li>
                    Summarize the techniques used in security assessments{" "}
                  </li>
                  <li>Explain the techniques used in penetration testing</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                Week 7: Architecture and Design
              </button>
            </h2>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Explain the importance of security concepts in an enterprise
                    environment{" "}
                  </li>
                  <li>
                    Summarize virtualization and cloud computing concepts.{" "}
                  </li>
                  <li>
                    Summarize secure application development, deployment, and
                    automation concepts.
                  </li>
                  <li>
                    Summarize authentication and authorization design concepts{" "}
                  </li>
                  <li>Given a scenario, implement cybersecurity resilience </li>
                  <li>
                    Explain the security implications of embedded and
                    specialized systems.{" "}
                  </li>
                  <li>
                    <i>
                      Exam: Threats, Attacks, and Vulnerabilities and analysis
                      of answers (Week 5-6)
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Week 8: Architecture and Design | Implementation
              </button>
            </h2>
            <div
              id="collapseEight"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Explain the importance of physical security controls.{" "}
                  </li>
                  <li>Explain the basics of cryptographic concepts </li>
                  <li>
                    Given a scenario, implement secure protocols, and host or
                    application security solutions,{" "}
                  </li>
                  <li>Given a scenario, implement secure network designs </li>
                  <li>
                    Given a scenario, install and configure wireless security
                    settings.
                  </li>
                  <li>
                    <i>
                      Exam on Architecture and Design and analysis of answers
                      (Week 7-8)
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                Week 9: Implementation
              </button>
            </h2>
            <div
              id="collapseNine"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Given a scenario, implement secure mobile solutions. </li>
                  <li>
                    Given a scenario, apply cybersecurity solutions to the
                    cloud.{" "}
                  </li>
                  <li>
                    Given a scenario, implement identity and account management
                    controls{" "}
                  </li>
                  <li>
                    Given a scenario, implement authentication and authorization
                    solutions{" "}
                  </li>
                  <li>Given a scenario, implement public key infrastructure</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                Week 10: Operations and Incident Response
              </button>
            </h2>
            <div
              id="collapseTen"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>
                    Given a scenario, use the appropriate tool to assess
                    organizational security.{" "}
                  </li>
                  <li>
                    Summarize the importance of policies, processes, and
                    procedures for incident response.{" "}
                  </li>
                  <li>
                    Given an incident, utilize appropriate data sources to
                    support an investigation.{" "}
                  </li>
                  <li>
                    Given an incident, apply mitigation techniques or controls
                    to secure an environment.{" "}
                  </li>
                  <li>Explain the key aspects of digital forensics. </li>
                  <li>
                    <i>
                      Exam on Implementation and analysis of answers (Week 9)
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                Week 11: Governance, Risk and Compliance
              </button>
            </h2>
            <div
              id="collapseEleven"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <li>Compare and contrast various types of controls. </li>
                  <li>
                    Explain the importance of applicable regulations, standards,
                    or frameworks that impact organizational security posture.{" "}
                  </li>
                  <li>
                    Explain the importance of policies to organizational
                    security.{" "}
                  </li>
                  <li>Summarize risk management processes and concepts </li>
                  <li>
                    Explain privacy and sensitive data concepts in relation to
                    security.{" "}
                  </li>
                  <li>
                    <i>
                      Exam on Operations and Incident Response and analysis of
                      answers (Week 10-11)
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="collapseTwelve"
              >
                Week 12: Exam and Job Guidance
              </button>
            </h2>
            <div
              id="collapseTwelve"
              class="accordion-collapse collapse accord"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body text-white">
                <ul>
                  <i>
                    <li>Final Exam</li>
                  </i>
                  <li>Final exam analysis of answers</li>
                  <li>Job support guidance</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <a
                class="face-button"
                href="/files/Brochure.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="face-primary">
                  <span class="icon fa fa-download"></span>
                  Course Details
                </div>

                <div class="face-secondary">
                  <span class="icon fa fa-hdd-o"></span>
                  Size: 4.80 MB
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Accordion end */}

      {/* Instructor start */}

      <section id="features" className="features" data-aos="fade-up">
        <div className="container">
          <div className="section-title">
            <h2>Course Instructors</h2>
          </div>

          <div className="row content">
            <div
              className="col-md-12 pt-4 text-center"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h1 className="name">Mesbah Islam</h1>
              {/* <h3 className="position">President & Instructor</h3> */}
              <p className="fst-italic">
                The lead instructor for CompTIA Security+ course is a veteran
                working in private and mostly in public sector as a software
                developer, Test Architect and Manager, and Cybersecurity
                professional. Currently he is performing the role of Information
                Security Manager in a highly visible federal agency. Our
                instructors have profound knowledge on market trend, demands,
                usability and hiring practices within public and private sector.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Instructor end */}

      {/* Price start */}

      <section id="price" className="pricing">
        <div className="container">
          <div className="section-title">
            <h2>Course Fee</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-md-4">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <h5 className="card-title text-muted text-uppercase text-center">
                    Price
                  </h5>
                  {/* <h6 className="card-price text-center">
                    $3500
                    <span className="period">One Time</span>
                  </h6> */}
                  <hr />

                  <h5 className="card-title text-muted text-uppercase text-center">
                    <a style={{ textDecoration: "none" }} href="/contact-us">
                      Call Us
                    </a>{" "}
                    For Course Fees
                  </h5>
                  {/* <h6 className="card-price text-center">
                    $3000
                    <span className="period">One Time</span>
                  </h6> */}
                  <hr />
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Lectures & Exams
                      {/* <strong>Lectures & Exams</strong> */}
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      MCQ Quizes
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Practical
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fas fa-check" />
                      </span>
                      Question Answering
                    </li>
                  </ul>
                  <div className="d-grid">
                    <a
                      href="/registration"
                      className="btn btn-primary text-uppercase"
                    >
                      Enroll
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Price end */}

      {/* table start */}
      <section>
        <div class="container">
          <div className="section-title">
            <h2>Current Course Schedule</h2>
          </div>
          <p>
            <strong>Classes: </strong>{courseDetails?.classes} | New York
            Time (EST)
            <br></br>
            <strong>Weekdays Classes: </strong>{courseDetails?.weekend_classes} | New York Time<br></br>
            <strong>Review Class: </strong>{courseDetails?.review_class} | New York
            Time (EST)
            <br></br>
          </p>
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Time</th>
                <th scope="col">Date (Weekend)</th>
                <th scope="col">Modules</th>
              </tr>
            </thead>
            <tbody>
              {
                sortWeeks(courseWeeks).map((week, index) => (
                  <tr key={week?.week}>
                    <th scope="row"></th>
                    <td>{week.week} ({courseDetails?.class_duration})</td>
                    <td>
                      {week.date_01}<br></br>
                      {week.date_02}
                    </td>
                    <td>
                      {formatModule(week.module)}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </section>

      {/* table end */}

      {/* tools start */}

      <section>
        <div className="container">
          <div className="section-title">
            <h2>Tools</h2>
          </div>
          <div className="row my-4">
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <i class="fab fa-windows mt-3"></i>
                  </h2>
                  <h5 className="card-title">Recon-no</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <img width={45} src={nmap} alt="" />
                  </h2>
                  <h5 className="card-title">Nmap</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block">
                  <h2>
                    <img width={100} src={NessusProfessional} alt="" />
                  </h2>
                  <h5 className="card-title">Nessus</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block mt-5">
                  <h2>
                    <img width={35} src={Burpsuite} alt="" />
                  </h2>
                  <h5 className="card-title">Burpsuite</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" text-center">
                <div className="card-block mt-5">
                  <h2>
                    <img width={35} src={Metasploit} alt="" />
                  </h2>
                  <h5 className="card-title">Metasploit</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* tools end */}
      <Footer />
    </>
  );
}

export default CyberSecurity;
