import { useState, useEffect, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Navbar from "./Navbar";
import Footer from "./Footer";
import { formSubmit } from "./api";

import Select from "react-select";
import countryList from "react-select-country-list";
import { Form } from "react-bootstrap";

import emailjs from 'emailjs-com';

import "./Registration.css";
import Acknowledgment from "./Acknowledgment";

function Registration() {
  const [firstNameRequired, setFirstNameRequired] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastNameRequired, setLastNameRequired] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailRequired, setEmailRequired] = useState("");
  const [email, setEmail] = useState("");
  const [phoneRequired, setPhoneRequired] = useState("");
  const [phone, setPhone] = useState("");
  const [cityRequired, setCityRequired] = useState("");
  const [city, setCity] = useState("");
  const [countryRequired, setCountryRequired] = useState("");
  const [country, setCountry] = useState("");
  const [stateRequired, setStateRequired] = useState("");
  const [state, setState] = useState("");
  const [source, setSource] = useState("");
  const [studentComment, setStudentComment] = useState("");
  const [postCodeRequired, setPostCodeRequired] = useState("");
  const [postCode, setPostCode] = useState("");
  const [streetRequired, setStreetRequired] = useState("");
  const [street, setStreet] = useState("");
  const [enrollRequired, setEnrollRequired] = useState("");
  const [enroll, setEnroll] = useState([]);
  const [paymentRequired, setPaymentRequired] = useState("");
  const [payment, setPayment] = useState([]);
  const [success, setSuccess] = useState("");
  const [successAlert, setSuccessAlert] = useState("");
  const [batchType, setBatchType] = useState("");
  const [batch, setBatch] = useState("");
  const [promo, setPromo] = useState("");
  const [location, setLocation] = useState("");
  const [batchRequired, setBatchRequired] = useState("");
  const [classLocationRequired, setClassLocationRequired] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [captchaRequired, setCaptchaRequired] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (country) => {
    setCountry(country);
  };

  const handleOnChange = (value) => {
    console.log("Captcha:" + value);
    setCaptcha(true);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleAcknowledgmentClick = (e) => {
    e.preventDefault();
    setShowModal(true);
    setIsChecked(true);
  };

  const handleClose = () => setShowModal(false);

  // send mail funtion....................................................................

  // Function to get the first two words of the program name
  function getFirstTwoWords(text) {
    return text.split(' ').slice(0, 4).join(' ');
  }
  const shortenedProgramInterested = getFirstTwoWords(enroll.toString());

  const sendEmail = async (data) => {
    const templateParams = {
      ...data,
    };

    try {
      const result = await emailjs.send(
        'service_p6h972r',
        'template_2499cjd',
        templateParams,
        'DBnVXFzCdt42lUxb2'
      );
      console.log(result.text);
      // Email sent successfully
    } catch (error) {
      console.error(error.text);
      // Error occurred while sending email
    }
  };



  const [isLoading, setIsLoading] = useState(false);


  const formValidation = async (event) => {
    event.preventDefault();
    if (firstName.length === 0) {
      setFirstNameRequired("First name is Required");
    } else {
      setFirstNameRequired("");
    }
    if (lastName.length === 0) {
      setLastNameRequired("Last name is Required");
    } else {
      setLastNameRequired("");
    }

    if (email.length === 0) {
      setEmailRequired("Email address is Required");
    } else {
      setEmailRequired("");
    }
    if (phone.length === 0) {
      setPhoneRequired("Phone number is required");
    } else {
      if (/[^0-9a-zA-Z]/.test(phone)) {
        setPhoneRequired("Phone number must be Alphanumeric");
      } else {
        setPhoneRequired("");
      }
    }
    // if (city.length === 0) {
    //   setCityRequired("City is required");
    // } else {
    //   if (/^[A-Za-z]+$/.test(city)) {
    //     setCityRequired("");
    //   } else {
    //     setCityRequired("City must have only characters");
    //   }
    // }

    if (city.length === 0) {
      setCityRequired("");
    } else {
      setCityRequired("");
    }

    if (country.length === 0) {
      setCountryRequired("Country is required");
    } else {
      setCountryRequired("");
    }

    if (batch.length === 0) {
      setBatchRequired("Batch is required");
    } else {
      setBatchRequired("");
    }

    if (location.length === 0) {
      setClassLocationRequired("Class Location is required");
    } else {
      setClassLocationRequired("");
    }

    if (state.length === 0) {
      setStateRequired("State is required");
    } else {
      if (/^[A-Za-z]+$/.test(state)) {
        setStateRequired("");
      } else {
        setStateRequired("State must have only characters");
      }
    }

    if (postCode.length === 0) {
      setPostCodeRequired("Zip code is required");
    } else {
      if (/^[0-9]*$/.test(postCode)) {
        setPostCodeRequired("");
      } else {
        setPostCodeRequired("Zipcode must have only numbers");
      }
    }
    if (street.length === 0) {
      setStreetRequired("Street is Required");
    } else {
      setStreetRequired("");
    }
    var flag = false;
    enroll.map((e) => {
      if (e.length > 0) {
        flag = true;
      }
    });
    if (!flag) {
      setEnrollRequired("Course is Required");
    } else {
      setEnrollRequired("");
    }
    flag = false;
    payment.map((e) => {
      if (e.length > 0) {
        flag = true;
      }
    });
    if (!flag) {
      setPaymentRequired("Payment is Required");
    } else {
      setPaymentRequired("");
    }

    if (!captcha) {
      setCaptchaRequired("Please solve the captcha");
    } else {
      setCaptchaRequired("");
    }

    if (
      firstName &&
      lastName &&
      email &&
      !cityRequired &&
      !phoneRequired &&
      !stateRequired &&
      street &&
      country.label &&
      !postCodeRequired &&
      enroll.length !== 0 &&
      payment.length !== 0 &&
      captcha
    ) {

      setIsLoading(true); // Set loading state to true

      try {
        // setSuccess("Thanks for submitting!");
        const data = {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email: email,
          phone_number: phone,
          state: state,
          street_address: street,
          country: country.label,
          city: city,
          post_code: postCode,
          source: source,
          student_comment: studentComment,
          program_interested: enroll.toString(),
          payment_type: payment.toString(),
          batch_name: batch,
          location: location,
          batch_type: batchType,
          promo_code: promo ? promo : "",
        };
        // .....................................................................................
        // Constructing the email template data
        if (
          firstName &&
          lastName &&
          email &&
          !cityRequired &&
          !phoneRequired &&
          !stateRequired &&
          street &&
          country.label &&
          !postCodeRequired &&
          enroll.length !== 0 &&
          payment.length !== 0 &&
          captcha
        ) {
          const emailTemplateData = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            email: email,
            phone_number: phone,
            state: state,
            street_address: street,
            country: country.label,
            city: city,
            post_code: postCode,
            source: source,
            student_comment: studentComment,
            program_interested: enroll.toString(),
            program_interested_shortened: shortenedProgramInterested,
            payment_type: payment.toString(),
            batch_name: batch,
            location: location,
            batch_type: batchType,
            promo_code: promo ? promo : "",
          };
          // Call the sendEmail function with the email template data

          sendEmail(emailTemplateData);

          console.log("EMAIL DATA", emailTemplateData);
          console.log("STMS FORM DATA", data);
        }
        // .....................................................................................

        formSubmit(data, (temp) => {
          if (temp.status === 201) {
            setSuccess("Thanks for submitting");
            setSuccessAlert("formSuccess");
          } else if (temp.status === 409) {
            setSuccess("System has a record with the provided email or phone number");
            setSuccessAlert("formFailed");
          }
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 10000);

      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }

    }

  };

  useEffect(() => {
    if (phone.length === 0) {
      setPhoneRequired("");
    } else {
      if (/[^0-9a-zA-Z]/.test(phone)) {
        setPhoneRequired("Phone number must be Alphanumeric");
      } else {
        setPhoneRequired("");
      }
    }

    // if (city.length === 0) {
    //   setCityRequired("");
    // } else {
    //   if (/^[A-Za-z]+$/.test(city)) {
    //     setCityRequired("");
    //   } else {
    //     setCityRequired("City must have only characters");
    //   }
    // }

    if (city.length === 0) {
      setCityRequired("");
    } else {
      setCityRequired("");
    }


    if (country.length === 0) {
      setCountryRequired("");
    } else {
      setCountryRequired("");
    }

    if (state.length === 0) {
      setStateRequired("");
    } else {
      if (/^[A-Za-z]+$/.test(state)) {
        setStateRequired("");
      } else {
        setStateRequired("State must have only characters");
      }
    }

    if (postCode.length === 0) {
      setPostCodeRequired("");
    } else {
      if (/^[0-9]*$/.test(postCode)) {
        setPostCodeRequired("");
      } else {
        setPostCodeRequired("Zipcode must have only numbers");
      }
    }
  }, [phone, city, country, state, postCode]);


  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1000px',
    maxHeight: '100vh',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    zIndex: 1001,
    overflow: 'auto',
  };

  return (
    <>
      <Navbar course="active" />
      {success && (
        <>
          <div className="success-box">
            <p className={successAlert}>{success}</p>
          </div>
        </>
      )}
      <div id="contact" className="registration-form">
        {!success && (
          <>
            <div className="left top-line">
              <div className="title-box">
                <h1>Please fill up the course registration form</h1>
              </div>
            </div>
            <div className=" d-flex align-items-stretch right ">
              <form onSubmit={formValidation} className="php-email-form">
                <p className={successAlert}>{success}</p>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="firstname">* First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="firstname"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <p className="inputRequired">{firstNameRequired}</p>
                  </div>
                  <div className="form-group col-md-4">
                    <label for="middlename"> Middle Name</label>
                    <input
                      type="text"
                      name="middlename"
                      className="form-control"
                      id="middlename"
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="lastname">* Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      className="form-control"
                      id="lastname"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <p className="inputRequired">{lastNameRequired}</p>
                  </div>
                  <div className="form-group col-md-6 mt-3 mt-md-0">
                    <label for="email">* Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="inputRequired">{emailRequired}</p>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="phone">* Phone Number</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <p className="inputRequired">{phoneRequired}</p>
                  </div>
                  <div className="form-group mt-3">
                    <label for="street">* Street Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      id="street"
                      onChange={(e) => setStreet(e.target.value)}
                    />
                    <p className="inputRequired">{streetRequired}</p>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="city">* City</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      id="city"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <p className="inputRequired">{cityRequired}</p>
                  </div>

                  <div className="form-group col-md-6">
                    <label for="country">* Country</label>
                    <Select
                      options={options}
                      value={country}
                      onChange={changeHandler}
                    />
                    {/* <input
                      type="text"
                      name="country"
                      className="form-control"
                      id="country"
                      onChange={(e) => setCountry(e.target.value)}
                    /> */}
                    <p className="inputRequired">{countryRequired}</p>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="state">* State</label>
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      id="state"
                      onChange={(e) => setState(e.target.value)}
                    />
                    <p className="inputRequired">{stateRequired}</p>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="zip">* Zipcode</label>
                    <input
                      type="text"
                      name="zip"
                      className="form-control"
                      id="zip"
                      onChange={(e) => setPostCode(e.target.value)}
                    />
                    <p className="inputRequired">{postCodeRequired}</p>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <label for="referral">
                    How did you hear about us? Please mention the name of your
                    referral source.
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="referral"
                    id="referral"
                    onChange={(e) => setSource(e.target.value)}
                  />
                </div>
                <hr></hr>
                <div className="form-group mt-3">
                  <label htmlFor="courses">* Courses you want to enroll</label>
                  <br />
                  <input
                    type="radio"
                    id="course1"
                    name="course"
                    value="Accessibility Testing"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="course1">
                    Accessibility Testing
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="course2"
                    name="course"
                    value="Software Testing with Selenium"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="course2">
                    Software Testing with Selenium
                  </label>
                  <br />
                  <input
                    type="radio"
                    id="course4"
                    name="course"
                    value="Data Analysis with Python"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="course4">
                    Data Analysis with Python
                  </label>
                  <br />
                  {/* <input
                    type="radio"
                    id="course3"
                    name="course"
                    value="Data Analysis with Power BI & SQL Server"
                    onChange={(e) => setEnroll([e.target.value])}
                  />
                  <label className="check" for="course3">
                    Data Analysis with Power BI & SQL Server
                  </label>
                  <br /> */}
                  {/* <br></br>

                  <input
                    type="radio"
                    id="course4"
                    name="course"
                    value="Cybersecurity for Professionals | Comprehensive CompTIA Security+ Certification (SY0-601)"
                    onChange={(e) => setEnroll([e.target.value])}
                  />

                  <label className="check cyber" for="course4">
                    Cybersecurity for Professionals | Comprehensive CompTIA
                    Security+ Certification (SY0-601)
                  </label> */}
                  <hr></hr>
                  <p className="inputRequired">{enrollRequired}</p>
                </div>

                <div className="form-group mt-3">
                  <label for="referral">* Batch you want to enroll</label>
                  <div className="form-group mt-3">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => {
                        setBatch(e.target.value);
                      }}
                    >
                      <option>Select batch</option>
                      <option value="January 2025">January 2025</option>
                      <option value="February 2025">February 2025</option>
                    </Form.Select>
                  </div>
                  <p className="inputRequired">{batchRequired}</p>
                </div>

                <div className="form-group mt-3">
                  <label for="referral">* Batch type</label>
                  <div className="form-group mt-3">
                    <Form.Select
                      aria-label="Default select example"
                      className="form-select-button"
                      onChange={(e) => {
                        setBatchType(e.target.value);
                      }}
                    >
                      <option>Select type</option>
                      <option value="Weekend">Weekend</option>
                      {/* <option value="Weekday">Weekday</option> */}
                    </Form.Select>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <label for="referral">* Class location</label>
                  <div className="form-group mt-3">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                    >
                      <option>Select location</option>
                      {/* <option value="New York">NY Branch</option>
                      <option value="Virginia">VA Branch</option> */}
                      <option value="Online">Attending online</option>
                    </Form.Select>
                  </div>
                  <p className="inputRequired">{classLocationRequired}</p>
                </div>

                <div className="radio">
                  <p>
                    * Payment Option (QA TEK will send you an email to your
                    inbox on how to send the payment based on the option you
                    pick.)
                  </p>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="radio1"
                      name="optradio"
                      value="zelle"
                      onChange={(e) => setPayment([e.target.value])}
                    />
                    Zelle<label class="form-check-label" for="radio1"></label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="radio2"
                      name="optradio"
                      value="cash"
                      onChange={(e) => setPayment([e.target.value])}
                    />
                    Cash<label class="form-check-label" for="radio2"></label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="optradio"
                      value="credit-card"
                      onChange={(e) => setPayment([e.target.value])}
                    />
                    Credit Card<label class="form-check-label"></label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="radio2"
                      name="optradio"
                      value="Check"
                      onChange={(e) => setPayment([e.target.value])}
                    />
                    Check<label class="form-check-label" for="radio2"></label>
                    <p className="inputRequired">{paymentRequired}</p>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <label for="referral">Promo Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="referral"
                    id="referral"
                    onChange={(e) => setPromo(e.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label for="name">Your Comment</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="10"
                    onChange={(e) => setStudentComment(e.target.value)}
                  ></textarea>
                </div>


                {/* <div className="form-check my-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="acknowledgeCheckbox"
                    checked={isChecked}
                    onChange={() => { }}
                    required
                  />
                  <label className="form-check-label" htmlFor="acknowledgeCheckbox">
                    I have read the{' '}
                    <a
                      href="/acknowledgment"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleAcknowledgmentClick}
                    >
                      Acknowledgement Form
                    </a>
                  </label>
                  {!isChecked && (
                    <p className="mt-2" style={{ fontSize: '0.8em', color: 'red' }}>Please click the acknowledgement form link to activate the button.</p>
                  )}
                </div> */}


                <div>
                  <div className="form-check my-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="acknowledgeCheckbox"
                      checked={isChecked}
                      onChange={() => { }}
                      required
                    />
                    <label className="form-check-label" htmlFor="acknowledgeCheckbox">
                      I have read the{' '}
                      <a
                        href="/acknowledgment"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleAcknowledgmentClick}
                      >
                        Acknowledgement Form
                      </a>
                    </label>
                    {!isChecked && (
                      <p className="mt-2" style={{ fontSize: '0.8em', color: 'red' }}>
                        Please click the acknowledgement form link to activate the button.
                      </p>
                    )}
                  </div>

                  {showModal && (
                    <div style={modalOverlayStyle}>
                      <div style={modalContentStyle}>

                        <Acknowledgment />

                        <button
                          style={{
                            position: 'fixed',
                            left: '50%',
                            transform: 'translate(-50%, -100%)',
                            backgroundColor: 'red',
                            border: 'none',
                            borderRadius: '20px',
                            padding: '8px 16px',
                            cursor: 'pointer',
                            color: 'white',
                            fontWeight: 'bold',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                          }}
                          onClick={handleClose}
                          id="acknowledgmentClose"
                        >
                          Close
                        </button>

                      </div>
                    </div>
                  )}
                </div>



                <ReCAPTCHA
                  sitekey="6LefNQEhAAAAAOhj4yy-VNVLgprB4ey5NjJ2RX7K"
                  onChange={handleOnChange}
                />

                <p className="inputRequired">{captchaRequired}</p>
                <div className="text-center">
                  {/* <button type="submit">SUBMIT</button> */}
                  <button type="submit" disabled={isLoading}>
                    {isLoading ?
                      <div>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span> </span>loading...
                      </div>
                      : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Registration;



